
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
    },
	async mounted(){
		await this.getBrands();
	},
	computed: {
		...mapGetters({
			getterBrands: 'getterBrands'
		})
	},
	methods: {
		...mapActions({
			getBrands: 'getBrands'
		})
	},
}
