
export default {
    props: ['type_menu', 'item_nav_option', 'index_nav_option'],
	components: {
		vcDynamicCustomLink		: () => import('../../components/pages/dynamic-custom-link.vue')
	},
    data() {
        return {
            activeItem          : '',
            activeChildItem     : '',
            activeSubChildItem  : ''
        }
    },
    methods: {
        isActive(menuItem) {
            
            return this.activeItem === menuItem;
        },
        setActive(menuItem) {
            
            this.activeItem = this.activeItem === menuItem ? '' : menuItem;
        },
        isActiveChild(menuChildItem) {
            
            return this.activeChildItem === menuChildItem;
        },
        setActiveChild(menuChildItem) {
            
            this.activeChildItem = this.activeChildItem === menuChildItem ? '' : menuChildItem;
        },
        isActiveSubChild(subChildItem) {
            
            return this.activeSubChildItem === subChildItem;
        },
        setActiveSubChild(subChildItem) {
            
            this.activeSubChildItem = this.activeSubChildItem === subChildItem ? '' : subChildItem;
        },
		setNavigationType(navigation_option){
			if( navigation_option.sub_navigation_type === 'basic' ){
				return 'dropdown';
			}
			else if( navigation_option.sub_navigation_type === 'full-width' ){
				return 'mega-menu';
			}
			else if ( navigation_option.sub_navigation_type === 'container-width' ){

				return 'columns'
			}
			else{
				return 'dropdown';
			}
		},
		navItemOptionOpen(event, navigate, menuItem, type_menu, type_item){
			if( menuItem ){
                if( type_item === 'child-item' ){
                    this.setActiveChild(menuItem);
                }
                else if( type_item === 'sub-child-item' ){
                    this.setActiveSubChild(menuItem);
                }
			}
            if( type_menu === 'mobile'){
                this.$root.$emit('close-sidebar');
            }
			navigate(event);
		}
    }
}
