
import { mapGetters, mapActions } from 'vuex'
import { formatData } from '../../constants/config.js'
export default {
    components: {
        vcLoading		    : () => import('../../components/pages/loading.vue' ),
        vcFormatPrice	    : () => import('../../components/product/format-price.vue' ),
        vcDynamicCustomLink	: () => import('../../components/pages/dynamic-custom-link.vue')
    },
    data() {
        return {
            loadingSidebarCart  : null,
            cartProducts	    : null,
            cartTotal		    : 0,
            countItems		    : 0,
            messages             : [],
            nextCheckout        : false,
            showMessage         : false,
            isLoadingMsg        : true,
        }
    },
    mounted() {
        this.$root.$on('show-sidebar-cart', this.showSidebarCart);
    },
    computed: {
        ...mapGetters({
            getterCart		: 'getterCart',
            getterSatement  : 'getterSatement',
        }),
        getLoadingSidebarCart(){
            return this.loadingSidebarCart;
        },
        getLoadingMsg(){
            return this.isLoadingMsg;
        },
        getShowMessage(){
            return this.showMessage;
        },
    },
    methods: {
        ...mapActions({
            actionCart: 'actionCart',
            getStatementByCustomer: 'getStatementByCustomer'
        }),
        formatCurrency(price, totalAmount = null){
            
            return formatData.currency(price, totalAmount);
        },
        async removeCartItem(product) {
            this.loadingSidebarCart = true;
            await this.actionCart({ action: 'remove', products: [{ product_id: product.product_id, variant_id: product.variant_id, quantity: parseInt(product.quantity) }] }).then( async (result_cart) => {
                
                await this.getStatementByCustomer().then( async (result_statement) => {
                    
                    let num_items = (this.getterCart.products || []).reduce( (previous_item, current_item) => {
                        
                        previous_item += current_item.quantity;
                        return previous_item;
                    }, 0 );
                    this.validNextCheckout( this.getterSatement.balance, this.getterSatement.preApprovedCredit, this.getterSatement.expiredDays, this.getterCart.details?.total, num_items, this.getterSatement.customer.first_order, this.getterSatement.customer.valid_moa );
                });
                this.loadingSidebarCart = null;
            });
        },
        validNextCheckout(balance_customer, pre_credit, expired_days, total_cart, num_items, first_order, valid_moa) {
            
            this.messages = [];
            this.nextCheckout = false;
            this.showMessage = false;
            
            if ( ( num_items > 0 ) && ( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) && ( ( first_order === null && ( total_cart > 1000 || valid_moa ) ) || ( first_order != null ) ) ) {
                
                this.nextCheckout = true;
                this.showMessage = false;
            }
            else if( ( num_items > 0 ) && ( ( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) || !( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) ) ){
                
                if( ( first_order === null ) && !( total_cart > 1000 || valid_moa ) ){
                    
                    this.messages.push(`Since we are wholesalers, the <strong class="font-color-secondary">Minimum Order Amount (MOA)</strong> of your first order must be a total of <strong class="font-color-secondary">$1,000.00</strong>.`);
                }
                else{
                    
                    this.messages.push(`Your available Maximum Purchase Limit is: <strong class="total-credit font-color-secondary">${ this.formatCurrency(pre_credit + balance_customer).format }</strong>, the total amount of your cart can not exceed this amount.<br> Your debt is <strong class="total-days font-color-secondary">${ expired_days }</strong> days past due.`);
                }
                this.nextCheckout = false;
                this.showMessage = true;
            }
            
            this.isLoadingMsg = null;
        },
        async showSidebarCart(is_show){
            if (is_show) {
                this.$refs.sidebarCart.show();
                this.loadingSidebarCart = true;
                await this.getStatementByCustomer().then( async (result_statement) => {
                    
                    let num_items = (this.getterCart.products || []).reduce( (previous_item, current_item) => {
                        
                        previous_item += current_item.quantity;
                        return previous_item;
                    }, 0 );
                    this.validNextCheckout( this.getterSatement.balance, this.getterSatement.preApprovedCredit, this.getterSatement.expiredDays, this.getterCart.details?.total, num_items, this.getterSatement.customer.first_order, this.getterSatement.customer.valid_moa );
                });
                this.loadingSidebarCart = null;
            } 
            else {
                this.$refs.sidebarCart.hide();
            }
        },
        closeSidebarCart(event, navigate){
            
            this.$refs.sidebarCart.hide();
            navigate(event);
        }
    }
}
