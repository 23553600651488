import { render, staticRenderFns } from "./icon-address-directory.vue?vue&type=template&id=1dae3998"
import script from "./icon-address-directory.vue?vue&type=script&lang=js"
export * from "./icon-address-directory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports