
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        searchResult	: () => import('../../components/header/search-result.vue')
    },
    data() {
        return {
            searchText	: ''
        }
    },
    mounted() {
        this.$root.$on('show-sidebar-search', this.showSidebarSearch);
    },
    computed: {
        ...mapGetters({
            getterSearchProduct		: 'getterSearchProduct'
        }),
        getSearchText(){
            return this.searchText;
        }
    },
    methods: {
        ...mapActions({
            searchProduct	: 'searchProduct',
        }),
        showSidebarSearch(is_show){
            if (is_show) {
                this.$refs.sidebarSearch.show();
            } else {
                this.searchText = '';
                this.$root.$emit('charge-search-result', { search_text: '', list: [], per_page: 5 });
                this.$refs.sidebarSearch.hide();
            }
        },
        searchProductWidget(event) {
            
            this.$cookies.set('value-search', this.getSearchText, { path: '/', maxAge: 60 * 60 * 24 * 7 });
            
            if ( this.getSearchText.trim() != '' ) {
                this.$root.$emit('execute-search', true);
                setTimeout(async () => {
                    await this.searchProduct({ search: this.getSearchText, per_page: 5 }).then( (result) => {
                        this.$root.$emit('charge-search-result', this.getterSearchProduct);
                        this.$root.$emit('execute-search', null);
                    });
                }, 500);
            }
        },
        selectSearchOption(){
            if ( this.getSearchText.trim() != '' ) {
                
                this.showSidebarSearch(false);
                this.$root.$emit('execute-view-more');
            }
        },
    }
}
