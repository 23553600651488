
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue')
	},
	computed: {
		...mapGetters({
			getterSlideshows	: 'getterSlideshows',
		}),
		getCatalogs(){
			
			return this.getterSlideshows?.catalogs?.slides ? this.getterSlideshows.catalogs.slides : [];
		},
	}
}
