
import { formatData } from "../../../constants/config.js";
import moment from "moment";
export default {
    components: {
        vcLoading   : () => import('../../../components/pages/loading.vue'),
        breadcrumbs : () => import('../../../components/pages/breadcrumbs.vue'),
        basicTable  : () => import('../../../components/pages/basic-table.vue')
    },
    data() {
        return {
            dataOrder: null,
            table_fields: [
            { key: 'created_at', label: 'Date', class: "w-15", formatter: (value, key, item) => { 
                return moment(value.toString().split(".")[0]).format('lll'); 
            } },
            { key: 'brand', label: 'Brand', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return item.brand.name; 
            }, sortByFormatted: true, filterByFormatted: true },
            { key: 'description', label: 'Description', class: "w-15" },
            { key: 'commissions', label: 'Commission', class: "w-15 d-md-table-cell d-none text-center", formatter: (value, key, item) => { 
                return this.formatCurrency(item.commissions.affiliate).format; 
            } },
            { key: 'released_at', label: 'Released At', class: "w-15 d-none d-sm-table-cell", formatter: (value, key, item) => { 
                return moment(value.toString().split(".")[0]).format('ll'); 
            } }
            ],
        };
    },
    async mounted(){
        
        this.$root.$on('open-sidebar-orders', this.openSideBarOrder);
    },
    methods: {
        formatCurrency(value){
            return formatData.currency(value);
        },
        openSideBarOrder(data) {
            this.dataOrder = data;
            this.$refs.orderDetails.show();
        },
        hideModal() {
            this.$refs.orderDetails.hide();
        },
    }
}
