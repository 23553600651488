
import { mapGetters, mapActions } from 'vuex';
export default {
	components: {
		breadcrumbs		: () => import('../../components/pages/breadcrumbs.vue'),
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
	},
	data(){
		return {
			searchLead: "",
			findAgent: null,
			profile_image: null,
			true: false
		}
	},
	mounted(){
	},
	computed: {
		...mapGetters({
			getterCustomerLeadAgent: 'getterCustomerLeadAgent'
		}),
		getAgent(){
			return this.findAgent;
		}
	},
	methods: {
		...mapActions({
			findCustomerLeadAgent: 'findCustomerLeadAgent'
		}),
		async searchAgent(){
			
			await this.findCustomerLeadAgent({ email: this.searchLead }).then( (result_agent) => {
				
				this.findAgent = this.getterCustomerLeadAgent?.agent;
				if( this.findAgent ){
					
					if( this.findAgent === null || this.findAgent.photo === '' ){
						
						this.profile_image = `${ this.$config.hefesto_file.baseURL }/images/svg-store/svg-profile-image/svg-secondary.svg`;
					}
					else{
						
						this.profile_image = `${ this.$config.agent.baseURL.replace('/api', '') }/${ this.findAgent.photo.replace('public/', '') }`;
					}
					this.hide_profile = false;
					this.findAgent.phone = this.findAgent.phone ? this.findAgent.phone : '';
					this.findAgent.whatsappLink = this.findAgent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.findAgent.phone.replace(/ +/g, '').replace('+', '') }` : '';
				}
				this.$refs['modal_agent'].show();
			});
		},
		cleanSearchAgent(){
			this.searchLead = "";
		}
	}
}
