
import { mapGetters, mapActions } from "vuex";
import { formatData, calcDiscountPrice, arrayHelpers } from "../../constants/config.js";
export default {
    props: ["product_origins"],
    name: 'cart-table',
    components: {
        vcLoading           : () => import('../../components/pages/loading.vue' ),
        productCollection   : () => import('../../components/product/product-collection.vue' ),
        vcFormatPrice       : () => import('../../components/product/format-price.vue' ),
		vcDynamicLink	    : () => import('../../components/pages/dynamic-link.vue')
    },
    data() {
        return {
            isLoadingMsg            : true,
            isLoadingTable          : true,
            customer                : null,
            statement               : null,
            changeCoupon            : false,
            applyCoupon             : null,
            coupon_name             : null, 
            details_cart            : { total_cart: 0, total_fixed: 0, total_percentage: 0, count_items: 0 },
            tableProducts           : null,
            select_products         : [],
            allSelected             : false,
            hideCellMobile          : true,
            
            showMessage             : false,
            
            nextCheckout            : false,
            
            search_products         : "",
            
            listBestSellers         : {
                desktop: [],
                mobile: []
            },
            message                 : null,
            balance_customer        : 0,
            pre_credit              : 0,
            expired_days            : 0,
            show_mobile_carousel    : false,
            discount_stock          : {
                value: 0,
                min_stock: 0,
                quantity: 0
            }
        };
    },
    async mounted() {
        
        window.addEventListener('load', this.resizeWindow);
        window.addEventListener('resize', this.resizeWindow);
        if( this.getterLogin && ['cart','save-later'].includes(this.product_origins) ){
            
            await this.getCart().then( async (result_cart) => {
                
                if( this.product_origins === 'cart' ){
                    
                    this.coupon_name        = this.couponName;
                    this.getListProductCart(this.getterCart.products);
                }
                else if( this.product_origins === 'save-later' ){
                    
                    this.getListProductCart(this.getterCartSaveLater.products);
                }
                await this.getStatementByCustomer().then( async (result_statement) => {
                    
                    this.formatStatement();
                });
            });
            await this.getListAddBestSellers({ product_origin: 'cart', action: 'list-products' }).then( (result_best_sellers) => {
                
                this.listBestSellers = {
                    desktop: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( this.getterListAddBestSellers ) ), 4 ),
                    mobile: arrayHelpers.chunkArray(JSON.parse( JSON.stringify( this.getterListAddBestSellers ) ), 2 )
                };
            });
        }
        else if( this.product_origins === 'buy-again' ){
            
            await this.listBuyAgainProducts().then( (result_buy_again) => {
                
                this.getListProductCart(this.getterListBuyAgain);
            });
        }
        else if( this.product_origins === 'preorder' ){
            
            await this.listProductPreorders().then( (result_preorder) => {
                
                this.getListProductCart(this.getterPreorders);
            });
        }
        else{
            this.isLoadingTable = null;
            this.tableProducts = [];
        }
    },
    computed: {
        ...mapGetters({
            getterLogin             : "getterLogin",
            getterUser              : "getterUser",
            getterCart              : "getterCart",
            getterCartSaveLater     : "getterCartSaveLater",
            getterListBuyAgain      : "getterListBuyAgain",
            getterPreorders         : "getterPreorders",
            getterCustomer          : "getterCustomer",
            getterCoupon            : "getterCoupon",
            getterSatement          : "getterSatement",
            getterDownloadCartFile  : 'getterDownloadCartFile',
            getterListAddBestSellers: 'getterListAddBestSellers'
        }),
        getShowMobileCarousel(){
            return this.show_mobile_carousel;
        },
        availableCoupon(){
            return !!this.getterCoupon?.name;
        },
        couponName(){
            return this.getterCoupon?.name ? this.getterCoupon.name : null;
        },
        getDetailsCart(){
            return this.details_cart;
        },
        getDiscountStock(){
            return this.discount_stock;
        },
        getTableProducts(){
            return this.tableProducts;
        },
        getLoadingTable(){
            return this.isLoadingTable;
        },
        getLoadingMsg(){
            return this.isLoadingMsg;
        },
        getListBestSellers(){
            return this.listBestSellers;
        },
        getHideCellMobile(){
            return this.hideCellMobile;
        },
        getSearchProducts(){
            return this.search_products;
        }
    },
    methods: {
        ...mapActions({
            getCart                 : "getCart",
            listBuyAgainProducts    : "listBuyAgainProducts",
            listProductPreorders    : "listProductPreorders",
            getCustomer             : "getCustomer",
            getStatementByCustomer  : "getStatementByCustomer",
            getListAddBestSellers   : "getListAddBestSellers",
            actionCart              : "actionCart",
            addNotification         : "addNotification",
            addCouponCart           : "addCouponCart",
            getCoupon               : "getCoupon",
            uploadCartFile          : "uploadCartFile",
            downloadCartFile        : "downloadCartFile",
            deleteCartFile          : "deleteCartFile",
        }),
        async formatStatement(){
            
            this.calcTotalCart();
            this.balance_customer   = this.getterSatement.balance;
            this.pre_credit         = this.getterSatement.preApprovedCredit;
            this.expired_days       = this.getterSatement.expiredDays;
            
            let num_items = (this.tableProducts || []).reduce( (previous_item, current_item) => {
                
                previous_item += current_item.quantity;
                return previous_item;
            }, 0 );
            this.validNextCheckout( this.balance_customer, this.pre_credit, this.expired_days, this.getDetailsCart.total_cart, num_items, this.getterSatement.customer.first_order, this.getterSatement.customer.valid_moa );
        },
        async formatCoupon(){
            
            this.isLoadingTable = null;
            this.coupon_name = this.getterCoupon?.name ? this.getterCoupon.name : '';
            this.applyCoupon = null;
            if( this.availableCoupon ){
                
                this.applyCoupon = this.getterCoupon.applyEligibility.type.indexOf('brand') ? this.getterCoupon.applyEligibility.brand.map( (item) => item.name ) : this.getterCoupon.applyEligibility.product.map( (item) => item.sku );
                this.applyCoupon = `This coupon is applicable to Selected ${ this.getterCoupon.applyEligibility.type.indexOf('brand') ? 'Brands' : 'SKUs' }, for more information contact your Sales Agent`;
            }
            if( this.changeCoupon ){
                
                this.changeCoupon = false;
                this.isLoadingMsg = true;
                this.showMessage = false;
                this.addCouponCart({ coupon_id: this.getterCoupon ? this.getterCoupon._id : null }).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
            else{
                
                this.isLoadingMsg = null;
            }
            if( this.product_origins === 'cart' ){
                
                this.getListProductCart(this.getterCart.products);
            }
            else if( this.product_origins === 'save-later' ){
                
                this.getListProductCart(this.getterCartSaveLater.products);
            }
            else if( this.product_origins === 'buy-again' ){
                
                await this.listBuyAgainProducts().then( (result_buy_again) => {
                    
                    this.getListProductCart(this.getterListBuyAgain);
                });
            }
            else if( this.product_origins === 'preorder' ){
                
                await this.listProductPreorders().then( (result_preorder) => {
                    
                    this.getListProductCart(this.getterPreorders);
                });
            }
            this.calcTotalCart();
        },
        async formatFileCart(){
            
            if( ![null, ""].includes( this.getterDownloadCartFile ) ){
                
                document.getElementById("download_file").href = `${ this.$config.hefesto_file.baseURL }${ this.getterDownloadCartFile }`;
                document.getElementById("download_file").download = `${ this.$config.hefesto_file.baseURL }${ this.getterDownloadCartFile }`.substring( `${ this.$config.hefesto_file.baseURL }${ this.getterDownloadCartFile }`.lastIndexOf('/') + 1 );
                document.getElementById("download_file").click();
                setTimeout(() => {
                    
                    this.deleteCartFile({ url_file: this.getterDownloadCartFile });
                }, 3000);
            }
        },
        resizeWindow(){
            this.hideCellMobile = window.innerWidth > 991;
            
            this.show_mobile_carousel = window.innerWidth <= 1200;
        },
        selectAll() {
            this.select_products = [];
            
            for (const item of this.tableProducts) {
                if( parseInt(item.quantity) > 0 ){
                    if (this.allSelected && parseInt(item.inventory_quantity) >= parseInt(item.quantity) ) {
                        document.getElementById(`check_${ item.variant_id }`).checked = true;
                        this.select_products.push({
                            product_id  : item.product_id,
                            variant_id  : item.variant_id,
                            quantity    : parseInt(item.quantity),
                            origin      : this.product_origins != 'save-later' ? 'cart' : this.product_origins
                        });
                    }
                    else if( parseInt(item.inventory_quantity) >= parseInt(item.quantity) ){
                        this.select_products = this.select_products.filter( (item_v) => item_v.variant_id != item.variant_id );
                        document.getElementById(`check_${ item.variant_id }`).checked = false;
                    }
                }
            }
            this.allSelected = this.select_products.length > 0;
            
        },
        select(variant_item) {
            
            this.allSelected = false;
            if( document.getElementById(`check_${ variant_item.variant_id }`).checked ){
                
                if( parseInt( variant_item.quantity ) > 0 ){
                    
                    let index_product_selected = this.select_products.findIndex( (item) => item.variant_id === variant_item.variant_id );
                    if( index_product_selected >= 0 ){
                        
                        this.select_products[index_product_selected].quantity = parseInt( variant_item.quantity );
                    }
                    else{
                        
                        this.select_products.push({
                            product_id  : variant_item.product_id,
                            variant_id  : variant_item.variant_id,
                            quantity    : parseInt( variant_item.quantity ),
                            origin      : this.product_origins != 'save-later' ? 'cart' : this.product_origins
                        });
                    }
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = true;
                }
                else{
                    
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = false;
                }
            }
            else{
                
                this.select_products = this.select_products.filter( (item) => item.variant_id != variant_item.variant_id );
            }
        },
        async changeQuantity(event_name, variant_item){
            
            let index_product = this.tableProducts.findIndex( (item) => item.variant_id === variant_item.variant_id );
            
            if( index_product >= 0 ){
                
                variant_item.quantity = ( this.tableProducts[index_product].sales_limit === 0 || ( this.tableProducts[index_product].sales_limit > 0 && parseInt( variant_item.quantity ) <= this.tableProducts[index_product].sales_limit ) ) ? parseInt( variant_item.quantity ) : this.tableProducts[index_product].sales_limit;
                
                this.tableProducts[index_product].quantity = this.validNumber( parseInt( variant_item.quantity ) <= this.tableProducts[index_product].inventory_quantity ? parseInt( variant_item.quantity ) : this.tableProducts[index_product].inventory_quantity );
                
                if( this.product_origins === 'buy-again' && this.tableProducts[index_product].quantity > 0  ){
                    
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = true;
                    this.select( variant_item );
                }
                document.getElementById(`quantity_${ variant_item.variant_id }`).value = this.validNumber(this.tableProducts[index_product].quantity);
                
                this.calcTotalCart();
                
                let count_stock_products    = 0;
                let min_stock          = 0;
                for (const item_variant of this.tableProducts) {
                    
                    if( item_variant.discounts.stock && item_variant.discounts.stock?.value > 0 ){
                        
                        min_stock              = min_stock < item_variant.discounts.stock.min_stock ? item_variant.discounts.stock.min_stock : min_stock;
                        count_stock_products   += item_variant.quantity;
                    }
                }
                this.discount_stock.quantity    = count_stock_products;
                if( ['cart', 'save-later'].includes(this.product_origins) && event_name === "change" ){
                    let data_products = {
                        products: [{
                            product_id  : this.tableProducts[index_product].product_id,
                            variant_id  : this.tableProducts[index_product].variant_id,
                            sku         : this.tableProducts[index_product].sku,
                            quantity    : parseInt( this.tableProducts[index_product].quantity ),
                            origin      : this.product_origins,
                        }],
                        action: this.product_origins === 'cart' ? 'update-product' : 'update-save-later', 
                    };
                    await this.actionCart(data_products).then( async (result_cart) => {
                        
                        await this.formatCoupon();
                    });
                    
                    if( ( ( this.getDetailsCart.total_cart / 100 ) > 0 ) && ( ( ( this.pre_credit + this.balance_customer ) >= ( this.getDetailsCart.total_cart / 100 ) && this.expired_days === 0 ) || !( ( this.pre_credit + this.balance_customer ) >= ( this.getDetailsCart.total_cart / 100 ) && this.expired_days === 0 ) ) ){
                        
                        this.isLoadingMsg = this.showMessage ? true : null;
                        this.showMessage = false;
                        await this.getStatementByCustomer().then( async (result_statement) => {
                            
                            this.formatStatement();
                        });
                    }
                }
            }
        },
        formatCurrency(price, totalAmount = null) {
            
            return formatData.currency(price, totalAmount);
        },
        getSubtotalCart(price, total_discount, discount_percentage, quantity){
            
            total_discount = ( discount_percentage ? ( total_discount + ( ( discount_percentage * ( 100 - total_discount ) ) / 100 ) ) : total_discount );

            return calcDiscountPrice(price, total_discount, quantity);
        },
        async addToCartSelected() {
            
            if (this.select_products.length > 0) {
                
                this.isLoadingTable = true;
                for (const item_variant of this.tableProducts) {
                    
                    if( document.getElementById(`quantity_${ item_variant.variant_id }`) ){
                        
                        document.getElementById(`quantity_${ item_variant.variant_id }`).value = this.validNumber(item_variant.quantity);
                        item_variant.quantity = 0;
                    }
                }
                await this.actionCart({ action: 'add', products: this.select_products }).then( async (result_cart) => {
                    
                    this.$root.$emit('show-sidebar-cart', true);
                    await this.formatCoupon();
                });
            } 
            else {
                
                this.addNotification({ content: { type: 'request' }, type: 'info', data: { title: "Info: Action Cart", message: "Please first select at least one item", app_version: this.$cookies.get('app-version') } });
            }
        },
        deleteItemsSelected() {
            
            this.actionsCart("remove");
        },
        deleteItemsCartLater() {
            
            this.actionsCart("delete-save-later");
        },
        saveForLaterSelected() {
            
            this.actionsCart("add-save-later");
        },
        removeSaveLaterSelected() {
            
            this.actionsCart("remove-save-later");
        },
        async addRemoveCoupon(){
            
            this.changeCoupon = true;
            this.isLoadingTable = true;
            if( this.availableCoupon ){
                
                await this.addCouponCart({ coupon_id: null }).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
            else{
                
                let extract_coupon_data = this.tableProducts.reduce( (previous_item, current_item) => {
                    
                    if( current_item.brand ){
                        
                        previous_item.brands.push( current_item.brand.name );
                    }
                    previous_item.skus.push( current_item.sku );
                    return previous_item;
                }, { brands: [], skus: [] });
                this.calcTotalCart();
                const payload = {
                    data: {
                        name: this.coupon_name,
                        cant_items: this.getDetailsCart.count_items,
                        total_amount: this.formatCurrency( this.getDetailsCart.total_cart, true ).number,
                        id_customer: this.getterSatement.customer._id,
                        id_brand: [...new Set(extract_coupon_data.brands)],
                        id_product: extract_coupon_data.skus
                    },
                };
                await this.getCoupon(payload).then( async (result_coupon) => {
                    
                    await this.formatCoupon();
                });
            }
        },
        validNextCheckout(balance_customer, pre_credit, expired_days, total_cart, num_items, first_order, valid_moa) {
            
            this.message = [];
            this.nextCheckout = false;
            this.showMessage = false;
            if ( ( num_items > 0 ) && ( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) && ( ( first_order === null && ( total_cart > 1000 || valid_moa ) ) || ( first_order != null ) ) ) {
                
                this.nextCheckout = true;
                this.showMessage = false;
            }
            else if( ( num_items > 0 ) && ( ( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) || !( ( pre_credit + balance_customer ) >= total_cart && expired_days === 0 ) ) ){
                
                if( ( first_order === null ) && !( total_cart > 1000 || valid_moa ) ){
                    
                    this.message.push(`Since we are wholesalers, the <strong class="font-color-secondary">Minimum Order Amount (MOA)</strong> of your first order must be a total of <strong class="font-color-secondary">$1,000.00</strong>.`);
                }
                else{
                    
                    this.message.push(`Your available Maximum Purchase Limit is: <strong class="total-credit font-color-secondary">${ this.formatCurrency(pre_credit + balance_customer).format }</strong>, the total amount of your cart can not exceed this amount.<br> Your debt is <strong class="total-days font-color-secondary">${ expired_days }</strong> days past due.`);
                }
                this.nextCheckout = false;
                this.showMessage = true;
            }
            
            this.isLoadingMsg = null;
        },
        isNumber(event, variant_item){
            
            if( variant_item.inventory_quantity >= parseInt( variant_item.quantity ) ){
                let keyCode = (event.keyCode ? event.keyCode : event.which);
                if ((keyCode < 48 || keyCode > 57) || parseInt(document.getElementById(`quantity_${ variant_item.variant_id }`).value) === 0 && parseInt(event.key) === 0 ) {
                    
                    event.preventDefault();
                }
                if( parseInt(document.getElementById(`quantity_${ variant_item.variant_id }`).value) === 0 && parseInt(event.key) > 0 ){
                    
                    document.getElementById(`quantity_${ variant_item.variant_id }`).value = null;
                    document.getElementById(`check_${ variant_item.variant_id }`).checked = false;
                }
            }
        },
        validNumber(number){
            
            return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
        },
        calcTotalCart(){
            
            if( this.tableProducts ){
                
                this.details_cart = this.tableProducts.reduce( (previous_item, current_item) => { 
                    
                    if( this.product_origins != 'preorder' || ( this.product_origins === 'preorder' && parseInt(current_item.inventory_quantity) >= parseInt(current_item.quantity) ) ){
                        
                        previous_item.count_items += parseInt( current_item.quantity );
                        let total_discount = current_item.total_discount;
                        
                        if( current_item.discounts.coupon.fixed > 0 ){
                            
                            previous_item.total_fixed   += current_item.discounts.coupon.fixed;
                        }
                        else if( current_item.discounts.coupon.percentage.value > 0 ){
                            
                            previous_item.total_percentage 	= current_item.discounts.coupon.percentage.value;
                            total_discount                = ( total_discount + ( ( current_item.discounts.coupon.percentage.value * ( 100 - total_discount ) ) / 100 ) );
                        }
                        previous_item.total_cart    	    += this.formatCurrency( calcDiscountPrice(current_item.price, total_discount, current_item.quantity) * parseInt(current_item.quantity), false ).number;
                    }
                    return previous_item; 
                }, { total_cart: 0, total_fixed: 0, total_percentage: 0, count_items: 0 } );
            }
        },
        getListProductCart(data_products){
            
            this.tableProducts          = [];
            let count_stock_products    = 0;
            let min_stock_high          = 0;
            
            for (const item_variant of data_products) {
                
                if( document.getElementById(item_variant.variant_id) ){
                    
                    if( this.product_origins === "buy-again" ){
                        
                        item_variant.quantity = 0;
                    }
                    document.getElementById(item_variant.variant_id).checked = false;
                }
                if( item_variant.discounts.stock && item_variant.discounts.stock?.value > 0 ){
                    
                    this.discount_stock.value   = item_variant.discounts.stock.value;
                    min_stock_high          = min_stock_high < item_variant.discounts.stock.min_stock ? item_variant.discounts.stock.min_stock : min_stock_high;
                    count_stock_products    += item_variant.quantity;
                }
                item_variant.min_quantity       = item_variant.moq > 0 ? item_variant.moq : 1;
                item_variant.inventory_quantity = item_variant.sales_limit > 0 ? item_variant.sales_limit : item_variant.inventory_quantity;
                item_variant.show               = true;
                
                this.tableProducts.push( item_variant );
            }
            this.discount_stock.min_stock   = min_stock_high;
            this.discount_stock.quantity    = count_stock_products;
            this.calcTotalCart();
            this.isLoadingTable               = null;
        },
        async actionsCart(action_cart){
            
            if (this.select_products.length > 0) {
                
                this.tableProducts  = [];
                this.isLoadingTable = true;
                this.isLoadingMsg   = true;
                this.showMessage    = false;
                await this.actionCart({ action: action_cart, products: this.select_products }).then( async (result_cart) => {
                    
                    await this.formatCoupon();
                });
            } 
            else {
                
                this.addNotification({ content: { type: 'request' }, type: 'info', data: { title: "Info: Action Cart", message: "Please first select at least one item", app_version: this.$cookies.get('app-version') } });
            }
        },
        filterProducts(){
            
            let regexp_search = new RegExp(this.getSearchProducts.trim().toLowerCase(), 'i');
            
            this.tableProducts = this.tableProducts.map( (item) =>{
                
                if( this.getSearchProducts.trim() === "" || regexp_search.test(`${ item.sku } ${ item.brand?.name || '' } ${ item.product_category?.name || '' } ${ item.title_product }`) ){
                    
                    item.show = true;
                }
                else{
                    item.show = false;
                }
                return item;
            });
        },
        goToCheckout(){
            
            this.$router.push(`/checkout/${ this.product_origins }`);
        },
        async addNewDoc(event) {
            
            if( document.getElementById("cart_file").files[0] ){
                
                let data_upload = {
                    file: document.getElementById("cart_file").files[0],
                    old_file_path: "",
                    max_size: 512000,
                    input_name: "cart_file",
                    extra_data: {
                        category_file: "excel",
                        body: [
                        {
                            name: "is_file",
                            value: JSON.stringify( true )
                        },
                        {
                            name: "multi_sheets", 
                            value: JSON.stringify( false ) 
                        },
                        { 
                            name: "sheets", 
                            value: JSON.stringify({ 
                                name: "Cart List", 
                                read_options: {
                                    max_columns: 3,
                                    first_reg: 3,
                                    fields: [
                                    { name: "sku"    	        , type: "string", format: "uppercase" }, 
                                    { name: "quantity"          , type: "number", format: "normal" }, 
                                    { name: "discount_price"    , type: "number", format: "money-ft" }
                                    ]
                                }
                            })
                        }
                        ]
                    }
                };
                this.tableProducts = [];
                this.isLoadingTable = true;
                this.isLoadingMsg = true;
                this.showMessage = false;
                await this.uploadCartFile(data_upload).then( async (result_cart) => {
                    
                    await this.formatCoupon();
                });
                // document.getElementById("cart_file").reset();
                // this.$refs.modalFile.hide();
            }
        },
        showModal(refName) {
            this.$refs[refName].show();
        },
        hideModal(refName) {
            this.$refs[refName].hide();
        },
        async downloadFile(event) {
            event.preventDefault();
            await this.downloadCartFile({ new_file: false, origin: this.product_origins, customer: this.getterSatement.customer._id.toString() }).then( async (result_file) =>{
                
                this.formatFileCart();
            });
        },
        async downloadTemplate(event) {
            event.preventDefault();
            await this.downloadCartFile({ new_file: true, origin: this.product_origins, customer: this.getterSatement.customer._id.toString() }).then( async (result_file) =>{
                
                this.formatFileCart();
            });
        },
    }
};
