
export default {
    name: 'LogoSvg',
    props: {
        width: {
            type: String,
            default: '164'
        },
        height: {
            type: String,
            default: '40'
        },
        primary_color: {
            type: String,
            default: '#FFFFFF'
        },
        secondary_color: {
            type: String,
            default: '#FFA072'
        },
        show_slogan: {
            type: Boolean,
            default: false
        },
        slogan: {
            type: String,
            default: 'Storefront'
        }
    }
}
