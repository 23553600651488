
import { mapGetters, mapActions } from "vuex";
import { formatData, arrayHelpers } from "../../constants/config.js";
export default {
    props: ['handleCollection', 'accessCatalog'],
    components: {
        vcFormatPrice   : () => import('../../components/product/format-price.vue'),
        countDown       : () => import( '../../components/pages/count-down.vue' )
    },
    data() {
        return {
            modal_product       : null,
            default_images      : [
            { width: 180, alt: "", src: '/images/products/product-default_320.webp' },
            { width: 320, alt: "", src: '/images/products/product-default_320.webp' }
            ],
            variantsTableItems  : [],
            activeColor         : null
        };
    },
    async mounted() {
        this.$root.$on('show-modal-variants', this.showModalVariants);
    },
    computed: {
        ...mapGetters({
            getterLogin: 'getterLogin',
        }),
        getModalProduct(){
            return this.modal_product;
        },
        getProductPreSale(){
            if( this.modal_product.config_bundle != null &&  this.modal_product.config_bundle?.config_pre_sale != null ){
                
                this.modal_product.config_bundle.config_pre_sale.time_left = moment(this.modal_product.config_bundle.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
                this.modal_product.config_pre_sale = this.modal_product.config_bundle.config_pre_sale;
            }
            else if( this.modal_product.config_pre_sale != null ){
                
                this.modal_product.config_pre_sale.time_left = moment(this.modal_product.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
            }
            return this.modal_product.config_pre_sale;
        },
        getCollectionImage01(){
            
            return this.getModalProduct.images?.length > 0 ? this.getModalProduct.images[0].src_collection : `${ this.$config.hefesto_file.baseURL }${ this.default_images[0].src }`;
        },
        getCollectionImage02(){
            
            return this.getModalProduct.images?.length > 1 ? this.getModalProduct.images[1].src_collection : `${ this.$config.hefesto_file.baseURL }${ this.default_images[1].src }`;
        },
        getActiveColor(){
            return this.activeColor;
        }
    },
    methods: {
        formatCurrency(price, totalAmount = null){
            
            return formatData.currency(price, totalAmount);
        },
        showModalVariants(item_product) {
            if( item_product.config_bundle != null && item_product.config_bundle?.config_pre_sale != null ){
                
                item_product.config_bundle.config_pre_sale.time_left = moment(item_product.config_bundle.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
            }
            else if( item_product.config_pre_sale != null ){
                
                item_product.config_pre_sale.time_left = moment(item_product.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
            }
            this.default_images[0].alt  = `${ item_product.title } - 1`;
            this.default_images[1].alt  = `${ item_product.title } - 2`;
            this.activeColor            = item_product.options.find( (item) => item.handle === 'color' ) ?.values[0] || null;
            item_product.variants       = item_product.variants.map( (item) => {
                
                return {
                    product_id			: item_product.shopify_id,
                    variant_id			: item.shopify_id,
                    sku					: item.sku,
                    options				: item.options,
                    image				: item.image,
                    price				: item.price,
                    discount_price		: item.discount_price,
                    discounts           : item_product.discounts,
                    inventory_quantity	: item.inventory_quantity,
                    show                : item.options.findIndex( (item) => item.handle === this.activeColor.handle ) >= 0
                };
            });
            this.variantsTableItems     = item_product.variants;
            this.modal_product          = item_product;
            this.$refs.modalVariants.show();
        },
        changeVariantTable(selectColor) {
            
            this.activeColor = selectColor;
            this.variantsTableItems = this.variantsTableItems.map( (item_v) => {
                
                item_v.show = item_v.options.findIndex( (item) => item.handle === this.activeColor.handle ) >= 0;
                return item_v;
            });
        }
    }
};
