
import { mapGetters, mapActions } from 'vuex';
export default {
	props: ["layout"],
	data() {
		return {
			showSearchResults	: false,
			searchText			: '',
			openSearch			: false,
			showSearch			: false
		}
	},
	components: {
		profileImage			: () => import('../account/profile-image.vue'),
		searchResult			: () => import('../../components/header/search-result.vue'),
		vcDynamicLink			: () => import('../../components/pages/dynamic-link.vue'),
		dynamicComponentLoader	: () => import('../../components/pages/dynamic-component-loader.vue'),
	},
	async mounted() {
		await this.loginUserEvent();
		
		window.addEventListener('load', this.resizewindow);
		window.addEventListener('resize', this.resizewindow);
		this.$root.$on('login-user', this.loginUserEvent);
		this.$root.$on("close-search-result", this.closeSearch);
		this.$root.$on("show-results-search", this.changeShowSearchResults);
		
	},
	computed: {
		...mapGetters({
			getterLogin				: 'getterLogin',
			getterBasicData			: 'getterBasicData',
			getterCartSaveLater		: 'getterCartSaveLater',
			getterSearchProduct		: 'getterSearchProduct',
			getterUser				: 'getterUser',
			getterLevel				: 'getterLevel'
		}),
		getSearchText(){
			return this.searchText;
		},
		getShowSearchResults(){
			return this.showSearchResults;
		},
	},
	methods: {
		...mapActions({
			getCart				: 'getCart',
			getLevelCustomer	: 'getLevelCustomer',
			searchProduct		: 'searchProduct',
			logoutUser			: 'logoutUser',
		}),
		async loginUserEvent(isLogin){
			
			if( this.layout === 'default' && this.getterLogin ){
				
				if( this.$route.path.indexOf('/account/cart') < 0 && this.$route.path.indexOf('/checkout/cart') ){
					
					await this.getCart();
				}
				await this.getLevelCustomer();
			}
		},
		resizewindow(event){
			this.openSearch = window.innerWidth <= 1200;
		},
		async executeSearchStore(){
			if( this.getSearchText.trim() != '' ){
				this.$root.$emit('execute-search', true);
				setTimeout(async () => {
					await this.searchProduct({ search: this.getSearchText, per_page: 4 }).then( (result) => {
						this.$root.$emit('charge-search-result', this.getterSearchProduct);
						this.$root.$emit('execute-search', null);
					});
				}, 500);
			}
		},
		closeSearch() {
			this.openSearch = false;
			this.searchText = "";
			this.showSearchResults = null;
			this.$root.$emit('charge-search-result', { search_text: '', list: [], per_page: 4 });
		},
		changeShowSearchResults(dataResults){
			this.showSearchResults = dataResults;
		},
		async searchProductWidget(event) {
			
			this.$cookies.set('value-search', this.getSearchText, { path: '/', maxAge: 60 * 60 * 24 * 7 });
			
			this.$root.$emit('execute-search', true);
			setTimeout(async () => {
				await this.searchProduct({ search: this.getSearchText, per_page: 4 }).then( (result) => {
					this.$root.$emit('charge-search-result', this.getterSearchProduct);
					this.$root.$emit('execute-search', null);
				});
			}, 500);
		},
		cleanSearch(){
			this.searchText = "";
			this.showSearchResults = null;
			this.$root.$emit('charge-search-result', { search_text: '', list: [], per_page: 4 });
			document.getElementById("search_top_header").focus();
		},
		openAccount($event){
			this.$root.$emit('show-sidebar-account', true);
		},
		logout() {
			this.logoutUser();
			this.$router.push('/account/login');
		}
	}
}
