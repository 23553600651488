
export default {
	layout: 'only-content',
	components: {
		vcDynamicLink	: () => import('../../../../components/pages/dynamic-link.vue'),
		vcLoading		: () => import('../../../../components/pages/loading.vue'),
        countDown   	: () => import('../../../../components/pages/count-down.vue')
    },
    data() {
        return {
			result  	: null, 
			title   	: null, 
			message 	: null, 
			error   	: null,
            isLoading	: true
        }
    },
    async mounted() {

		this.result   = decodeURI( this.$route.query.result );
		this.title    = decodeURI( this.$route.query.title );
		this.message  = decodeURI( this.$route.query.message );
		this.error    = decodeURI( this.$route.query.error );

        this.$root.$on('end-count-down', this.endCountDown);
        if( !['null', null, 'undefined', undefined].includes(this.$route.query?.store) ){

            window.location.href = `${ this.$config.storefront_url }/${ this.$route.query.store }${ this.$route.fullPath }${ !['null', null, 'undefined', undefined].includes(this.$route.query?.code_af) ? `&code_af=${ this.$route.query.code_af }` : '' }`;
        }
        else{
            
            this.isLoading = null;
            this.$root.$emit('init-count-down', 30);
        }
    },
    computed:{
        getLoading(){
            return this.isLoading;
        }
    },
    methods: {
        endCountDown(){
            console.log( 'end count down' );
            this.$router.push('/account/cart');
        }
    }
}
