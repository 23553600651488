
export default {
	components: {
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
	},
	data(){
		return {
			typeErr: null
		}
	},
	mounted() {
		this.typeErr = this.$route.query.err || null
	},
}
