
import { mapGetters, mapActions } from "vuex";
export default {
	components: {
		breadcrumbs: () => import('../../components/pages/breadcrumbs.vue'),
		vcLoading: () => import('../../components/pages/loading.vue'),
		formPassword: () => import('../../components/pages/form-password.vue')
	},
	data() {
		return {
			isLoading: null,
			email: null,
			availableChangePassword: false,
		}
	},
	computed:{
		...mapGetters({
			getterValidCustomer: "getterValidCustomer",
			getterChangePassword: "getterChangePassword"
		}),
		getUserEmail(){
			return this.email;
		}
	},
	mounted(){
		this.$root.$on('change-loading', this.updateLoading);
	},
	methods: {
		...mapActions({
			validCustomer: "validCustomer"
		}),
		updateLoading(dataLoading){
			
			this.isLoading = dataLoading;
		},
		async validCustomerEmail(){
			this.isLoading = true;
			await this.validCustomer({ email: this.email }).then( (result_customer) => {
				
				this.resultValidCustomerEmail();
			}).catch( (error_customer) => {
				
				this.resultValidCustomerEmail();
			});
		},
		resultValidCustomerEmail(){
			
			console.log( this.getterValidCustomer );
			this.isLoading = null;
			this.availableChangePassword = this.getterValidCustomer.success;
		}
	}
}
