
export default {
    name: 'DynamicComponentLoader',
    props: {
        filename: {
            type: String,
            required: true
        },
        props: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        asyncComponent() {
            return () => import(`@/components/${this.filename}.vue`);
        }
    }
};
