
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        vcLoading   : () => import('../../../components/pages/loading.vue'),
        breadcrumbs : () => import('../../../components/pages/breadcrumbs.vue'),
        formAddress : () => import('../../../components/pages/form-address.vue'),
        vcFormField	: () => import('../../../components/pages/form-field.vue')
    },
    data() {
        return {
            is_loading_page             : true,
            
            address_form                : null,
            list_addresses              : [],
            list_countries              : [],
            list_phone_codes            : [],
            list_states                 : [],
            
            default_address             : {
                id                  : null,
                customer_id         : null,
                default_shipping    : false,
                default_billing     : false,
                first_name          : '',
                last_name           : '',
                name                : '',
                phone               : '',
                address_1           : '',
                address_2           : '',
                company             : '',
                country             : 'United States',
                country_code        : 'US',
                state               : null,
                state_code          : null,
                city                : '',
                zip                 : ''
            },
            is_default_shipping_address : false,
            is_default_billing_address  : false,
            select_address_id           : null,
            
            valid_form                  : false
        };
    },
    async mounted(){
        
        this.$root.$on('update-field-value'	, this.updateFieldValue);
        if( this.getterLogin ){
            
            await this.getCountries().then( async (result_countries) => {
                
                this.list_phone_codes = this.getterCountries.phone_codes;
                this.list_countries 	= this.getterCountries.list;
                this.list_states     = this.getterCountries.states;
                await this.listCustomerAddress({ id_email: this.getterUser.email }).then( (result_customer) => {
                    
                    this.default_address.customer_id = this.getterCustomer.shopify_id;
                    this.formatListAddress();
                    
                    this.is_loading_page = null;
                });
                this.$root.$emit('change-select-address', { id_form: "register-shipping-address", address: this.address_form });
            });
            this.$root.$on('valid-form-address', this.validFormAddress);
        }
        else{
            
            this.$router.push('/account/login');
        }
    },
    computed: {
        ...mapGetters({
            getterLogin             : 'getterLogin',
            getterUser              : 'getterUser',
            getterCustomer          : "getterCustomer",
            getterCustomerAddresses : "getterCustomerAddresses",
            getterCountries         : "getterCountries"
        }),
        getLoadingPage(){
            return this.is_loading_page;
        },
        getAddressForm(){
            this.is_default_shipping_address    = (this.address_form ? this.address_form : this.default_address)?.default_shipping || false;
            this.is_default_billing_address     = (this.address_form ? this.address_form : this.default_address)?.default_billing || false;
            return this.address_form ? this.address_form : this.default_address;
        },
        getDefaultShippingAddress(){
            return this.is_default_shipping_address;
        },
        getDefaultBillingAddress(){
            return this.is_default_billing_address;
        },
        getDataListCountries(){
            return this.list_countries;
        },
        getDataListStates(){
            return this.list_states;
        },
        getDataListPhoneCodes(){
            return this.list_phone_codes;
        },
        getListAddresses(){
            return this.list_addresses;
        },
        getSelectAddressId(){
            return this.select_address_id;
        }
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    methods: {
        ...mapActions({
            getCountries            : 'getCountries',
            listCustomerAddress     : 'listCustomerAddress',
            saveCustomerAddress     : 'saveCustomerAddress',
            deleteCustomerAddress   : 'deleteCustomerAddress',
        }),
        async updateFieldValue( data ){
            if( data.field === 'select_address'){
                
                this.changeAddress( data.value );
            }
        },
        async validFormAddress(data_form){
            this.valid_form      = data_form.valid;
            this.address_form    = data_form.address;
            
            if( this.valid_form ){
                
                this.is_loading_page = true;
                
                await this[data_form.action]({ address: this.address_form }).then( (result_address) => {
                    
                    this.formatListAddress();
                });
                this.valid_form = false;
            }
            this.is_loading_page = null;
        },
        async saveAddress() {
            
            this.$root.$emit('valid-address', { id_form: "register-shipping-address", action: "saveCustomerAddress" });
        },
        async deleteAddress() {
            
            this.$root.$emit('valid-address', { id_form: "register-shipping-address", action: "deleteCustomerAddress" });
        },
        addAddress(){
            
            this.changeAddress(null);
        },
        changeDefaultShippingAddress(isDefault){
            
            this.is_default_shipping_address = isDefault;
            this.address_form.default_shipping = isDefault;
        },
        changeDefaultBillingAddress(isDefault){
            
            this.is_default_billing_address = isDefault;
            this.address_form.default_billing = isDefault;
        },
        changeAddress(address_id) {
            
            if (this.getterCustomer) {
                
                this.address_form 	= this.list_addresses.find((item) => item.id === address_id )?.value;
                
                if (this.address_form) {
                    
                    this.select_address_id = address_id;
                    this.$root.$emit('change-select-address', { id_form: "register-shipping-address", address: this.address_form });
                }
            }
        },
        formatListAddress(){
            
            this.list_addresses      = this.getterCustomerAddresses.map((item) => {
                
                return { 
                    label               : `${ item.first_name } ${ item.last_name } - ${ item.address_1 } ${ item.city } ${ item.zip } ${ item.country } ${ item.state }`, 
                    id                  : item.id, 
                    default_shipping    : item.default_shipping, 
                    default_billing     : item.default_billing,
                    value               : item 
                };
            });
            this.list_addresses      = [{ label: `New Address`, id: this.default_address.id, default_shipping: false, default_billing: false, value: this.default_address }].concat( 
                this.list_addresses.filter( (item) => item.default_shipping && item.default_billing ), 
                this.list_addresses.filter( (item) => item.default_shipping && !item.default_billing ), 
                this.list_addresses.filter( (item) => !item.default_shipping && item.default_billing ), 
                this.list_addresses.filter( (item) => !item.default_shipping && !item.default_billing ) 
            );
            
            this.address_form        = this.list_addresses.find((item) => item.default_shipping );
            
            this.address_form        = this.address_form && !this.address_form.value.country && !this.address_form.value.state ? this.getterCustomerAddresses.find( (item) => item.country && item.state ) : this.address_form.value;
            
            this.address_form        = this.address_form ? this.address_form : {...this.default_address};
            
            this.select_address_id    = this.address_form.id;
        }
    }
}
