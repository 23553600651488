
import { mapGetters, mapActions } from 'vuex';
import { formatData } from "../../../constants/config.js";
import moment from "moment";
export default {
    components: {
        vcLoading   : () => import('../../../components/pages/loading.vue'),
        breadcrumbs : () => import('../../../components/pages/breadcrumbs.vue'),
        basicTable  : () => import('../../../components/pages/basic-table.vue')
    },
    data() {
        return {
            isLoagingPage: null,
            data_order: null,
            table_fields: [
            { key: 'created_at', label: 'Date', class: "w-15", formatter: (value, key, item) => { 
                return moment(value.toString().split(".")[0]).format('lll'); 
            } },
            { key: 'name', label: 'Order', class: "w-15" },
            { key: 'quantity_items', label: 'Items', class: "w-15 d-md-table-cell d-none text-center" },
            { key: 'total_price', label: 'Total', class: "w-15 d-md-table-cell d-none", formatter: (value, key, item) => { 
                return this.formatCurrency(value).format; 
            } },
            { key: 'financial_status', label: 'Financial Status', class: "w-15 d-none d-sm-table-cell" },
            { key: 'fulfillments', label: 'Fulfillment', class: "w-15 d-none d-sm-table-cell" },
            { key: 'actions', label: 'Actions', class: "w-10 hide-title" }
            ],
        };
    },
    async mounted(){
        
        this.$root.$on('open-sidebar-orders', this.openSideBarOrder);
    },
    computed: {
        ...mapGetters({
            getterOrder: 'getterOrder'
        }),
        getDataOrder(){
            return this.data_order;
        },
        getLoagingPage(){
            return this.isLoagingPage;
        }
    },
    methods: {
        ...mapActions({
            getOrder: 'getOrder'
        }),
        formatCurrency(value){
            return formatData.currency(value);
        },
        async openSideBarOrder(data) {
            
            this.isLoagingPage = true;
            await this.getOrder({ name: data.name }).then(() => {
                
                if( this.getterOrder != null ){
                    
                    this.data_order = this.getterOrder;
                    this.$refs.orderDetails.show();
                };
                this.isLoagingPage = null;
            });
        },
        hideModal() {
            this.$refs.orderDetails.hide();
            this.data_order = null;
        },
    }
}
