
import moment from "moment";
import { mapGetters, mapActions } from 'vuex';
export default {
	props: ["product", "index", "imgList", "handleCollection", "hideDescription"],
	components: {
		vcLoading		: () => import('../../components/pages/loading.vue'),
		vcFormatPrice	: () => import('../../components/product/format-price.vue'),
		countDown   	: () => import( '../../components/pages/count-down.vue' ),
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
	},
	data() {
		return {
			default_images: [
			{ width: 180, alt: this.product.title, src: '/images/products/product-default_320.webp' },
			{ width: 320, alt: this.product.title, src: '/images/products/product-default_320.webp' }
			]
		};
	},
	mounted() {
		
		if( this.product.config_bundle != null && this.product.config_bundle?.config_pre_sale != null ){
			
			this.product.config_bundle.config_pre_sale.time_left = moment(this.product.config_bundle.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
			this.product.config_pre_sale = this.product.config_bundle.config_pre_sale;
		}
		else if( this.product.config_pre_sale != null ){
			
			this.product.config_pre_sale.time_left = moment(this.product.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
		}
	},
	computed: {
		...mapGetters({
			getterLogin: 'getterLogin',
			getterVariants: 'getterVariants'
		}),
		getCollectionImage01(){
			
			return this.product.images?.length > 0 ? this.product.images[0].src_collection : `${ this.$config.hefesto_file.baseURL }${ this.default_images[0].src }`;
		},
		getCollectionImage02(){
			
			return this.product.images?.length > 1 ? this.product.images[1].src_collection : `${ this.$config.hefesto_file.baseURL }${ this.default_images[1].src }`;
		},
		getProductPreSale(){
			if( this.product.config_bundle != null &&  this.product.config_bundle?.config_pre_sale != null ){
				
				this.product.config_bundle.config_pre_sale.time_left = moment(this.product.config_bundle.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
				this.product.config_pre_sale = this.product.config_bundle.config_pre_sale;
			}
			else if( this.product.config_pre_sale != null ){
				
				this.product.config_pre_sale.time_left = moment(this.product.config_pre_sale.ended_at.split('T')[0]).endOf('day').utc().diff(moment(), 'seconds') || 0;
			}
			return this.product.config_pre_sale;
		},
	},
	methods:{
		...mapActions({
			getProductVariants: 'getProductVariants'
		}),
		async showModalVariants(){
			
			this.$root.$emit('loading-content-collection', true);
			await this.getProductVariants({ handle: this.product.handle }).then(() => {
				
				if( this.getterVariants ){
					
					this.product.variants = this.getterVariants.variants;
					this.$root.$emit('show-modal-variants', this.product);
					this.$root.$emit('loading-content-collection', null);
				}
				else{
					
					this.$root.$emit('loading-content-collection', null);
				}
			}).catch( () => {
				
				this.$root.$emit('loading-content-collection', null);
			});
		}
	}
};
