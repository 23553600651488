
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
    props: [{
        type_logo: {
            type: String,
            default: 'white'
        }
    }],
    components: {
        vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
    },
    data() {
        return {
            swipper_testimonial_avatar: {
                direction: 'vertical',
                slidesPerView: 3,
                spaceBetween: 0,
                mousewheel: true,
                centeredSlides: true
            },
            swipper_testimonial_content: {
                direction: 'vertical',
                slidesPerView: 1,
                spaceBetween: 0,
                mousewheel: true,
                centeredSlides: true
            },
            swipper_testimonial: {
                slidesPerView: 1,
                spaceBetween: 0,
                mousewheel: true,
                centeredSlides: true,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                }
            },
            testimonial_slideshow: [],
            show_desktop: true
        }
    },
    mounted(){
        this.testimonial_slideshow = this.getterTestimonials ? this.getRandomItems( this.getterTestimonials, this.getterTestimonials.length ): [];
        
        window.addEventListener('load', this.loadTestimonials());
    },
    computed:{
        ...mapGetters({
            getterTestimonials: 'getterTestimonials',
        }),
        getSlideshow(){
            
            return this.testimonial_slideshow;
        },
        avatarSwiper() {
            return this.$refs.avatarSwiper.$swiper
        },
        testimonialSwiper() {
            return this.$refs.testimonialSwiper.$swiper
        },
        contentSwiper() {
            return this.$refs.contentSwiper.$swiper
        }
    },
    methods: {
        hideAvatarImage(index){
            
            this.testimonial_slideshow[index].author.avatar = null;
        },
        testimonialDate(date){
            
            return moment(date).fromNow();
        },
        getRandomItems(array, numItems) {
            return array.sort(() => 0.5 - Math.random()).slice(0, numItems);
        },
        syncContentSwiper() {
            this.contentSwiper.slideTo(this.avatarSwiper.activeIndex);
        },
        syncAvatarSwiper() {
            this.avatarSwiper.slideTo(this.contentSwiper.activeIndex);
        },
        loadTestimonials(){
            
            if( window.innerWidth >= 768 ){
                
                
                this.show_desktop = true;
                setTimeout(() => {
                    this.avatarSwiper.slideTo(1);
                    this.contentSwiper.slideTo(1);
                }, 400);
            }
            else{
                
                this.show_desktop = false;
                setTimeout(() => {
                    this.testimonialSwiper.slideTo(0);
                }, 400);
            }
        }
    }
}
