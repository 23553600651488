
export default {
	components: {
		vcFormField: () => import("../../components/pages/form-field.vue"),
	},
	data() {
		return {
			cardFields				: { 
				cardName	: '', 
				cardNumber	: '', 
				cardYear	: new Date().getFullYear(), 
				cardMonth	: new Date().getMonth() + 1, 
				cardCvv		: null 
			},
			cardInputs				: { 
				cardNumber	: 'v-card-number', 
				cardName	: 'v-card-name', 
				cardMonth	: 'v-card-month', 
				cardYear	: 'v-card-year', 
				cardCvv		: 'v-card-cvv'
			},
			cardLabels				: {  
				cardNumber	: 'Card Number',
				cardName	: 'Full Name', 
				cardHolder	: 'Holder Name', 
				cardMonth	: 'MM', 
				cardYear	: 'YY', 
				cardExpires	: 'Expires', 
				cardCvv		: 'CVV'
			},
			cardFocus				: { 
				cardNumber	: false, 
				cardName	: false, 
				cardMonth	: false, 
				cardYear	: false, 
				cardCvv		: false
			},
			
			isCardNumberMasked		: false,
			hasRandomBackgrounds	: true,
			backgroundImage			: require( '@/assets/images/background-gray_full.webp' ),
			setType					: '',
			
			mainCardNumber			: '',
			cardNumberMaxLength		: 19,
			generatedType			: '',
			cardCcvMaxNumber		: 3,
			
			errorCardDate			: null,
			errorCardNumber			: null,
			
			minCardYear				: new Date().getFullYear()
		};
	},
	directives: {
		'number-only': {
			bind (el) {
				el.addEventListener('keypress', (event) => {
					event.target.value = event.target.value.replace(/[^0-9]/g, '')
					if (event.charCode >= 48 && event.charCode <= 57) {
						return true
					}
					event.preventDefault()
				})
			}
		},
		'letter-only': {
			bind (el) {
				el.addEventListener('keypress', (event) => {
					if (event.charCode >= 48 && event.charCode <= 57) {
						event.preventDefault()
					}
					return true
				})
			}
		}
	},
	async mounted() {
		
		this.$root.$on('clean-credit-card', this.cleanFields);
        this.$root.$on('valid-credit-card-fields', this.executeValidCreditCard);
	},
	computed: {
		getCardFocus(){
			return this.cardFocus;
		},
		getCardFields(){
			return this.cardFields;
		},
		getCardCcvMaxNumber(){
			return this.cardCcvMaxNumber;
		},
		getCardNumberMaxLength(){
			return this.cardNumberMaxLength;
		},
		getErrorCardNumber(){
			return this.errorCardNumber;
		},
		getErrorCardDate(){
			return this.errorCardDate;
		},
		minCardMonth () {
			if (this.cardFields.cardYear === this.minCardYear) { 
				return new Date().getMonth() + 1 
			}
			return 1
		},
		optionMonths() {
			
			let listMonths 		= [];
			for (let item_month = 1; item_month <= 12; item_month++) {
				
				listMonths.push({ 
					value: item_month,
					text: item_month > 9 ? (item_month).toString() : `0${ item_month }`, 
					disabled: item_month < this.minCardMonth
				});
			}
			return listMonths;
		},
		optionYears() {
			
			let currentYear = parseInt( new Date().getFullYear() );
			let listYears 	= [{ value: currentYear, text: currentYear }];
			for (let item_year = 1; item_year <= 14; item_year++) {
				
				listYears.push({ 
					value: (currentYear + item_year).toString(),
					text: (currentYear + item_year).toString(), 
				});
			}
			return listYears;
		},
	},
	methods: {
		updateFieldFocus(value, field){
			this.cardFocus[field] = value;
		},
		cleanFields(){
			this.cardFields = { 
				cardName	: '', 
				cardNumber	: '', 
				cardYear	: new Date().getFullYear(), 
				cardMonth	: new Date().getMonth() + 1, 
				cardCvv		: null 
			};
		},
		changeCardName(input_value) {
			
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardType(card_type) {
			
			this.generatedType = card_type;
			
			if (this.cardFields.cardNumber != "" && card_type === "") {
				
				this.errorCardNumber = 'Invalid Card Number';
			} 
			else{
				
				this.errorCardNumber = null;
			}
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardNumber() {
			
			const value = this.cardFields.cardNumber.replace(/\D/g, '')
			// american express, 15 digits
			this.cardCcvMaxNumber = 3;
			if ( /^3[47]\d{0,13}$/.test(value) ) {
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardCcvMaxNumber = 4;
				this.cardNumberMaxLength = 17;
			} else if ( /^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value) ) {
				// diner's club, 14 digits
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ');
				this.cardNumberMaxLength = 16;
			} else if ( /^62[0-9]\d*/.test(value) ) {
				
				this.cardFields.cardNumber = value.replace(/(\d{6})/, '$1 ').replace(/(\d{6}) (\d{7})/, '$1 $2 ').replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ').replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4');
				this.cardNumberMaxLength = 21;
			} else if ( /^\d{0,16}$/.test(value) ) {
				// regular cc number, 16 digits
				this.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ');
				this.cardNumberMaxLength = 19;
			}
			
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardMonth(input_value) {
			
			if( this.cardFields.cardYear != null && this.cardFields.cardMonth != null && parseInt( this.cardFields.cardMonth ) < new Date().getMonth() + 1 && parseInt( this.cardFields.cardYear ) === new Date().getFullYear() ){
				
				this.errorCardDate = "Invalid Card Date";
			}
			else{
				
				this.errorCardDate = null;
			}
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardYear(input_value) {
			
			if(  this.cardFields.cardYear != null && this.cardFields.cardMonth != null && parseInt( this.cardFields.cardMonth ) < new Date().getMonth() + 1 && parseInt( this.cardFields.cardYear ) === new Date().getFullYear() ){
				
				this.errorCardDate = "Invalid Card Date";
			}
			else{
				
				this.errorCardDate = null;
			}
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		changeCardCvv(input_value) {
			
			this.$root.$emit("change-credit-card", { credit_card: this.cardFields, valid: !this.errorCardDate && !this.errorCardNumber });
		},
		validCardFields(){
			
		},
        executeValidCreditCard(data_form){
            
			document.getElementById('btn-form-credit-card').click();
        },
        preSubmit(is_valid){
            
			if (this.cardFields.cardNumber != "" && this.generatedType === "") {
				
				this.errorCardNumber = 'Invalid Card Number';
			} 
			else{
				
				this.errorCardNumber = null;
			}
			if( this.cardFields.cardYear != null && this.cardFields.cardMonth != null && parseInt( this.cardFields.cardMonth ) < new Date().getMonth() + 1 && parseInt( this.cardFields.cardYear ) === new Date().getFullYear() ){
				
				this.errorCardDate = "Invalid Card Date";
			}
			this.$root.$emit("valid-credit-card", !this.errorCardDate && !this.errorCardNumber && is_valid);
        }
	}
};
