
export default {
    props:["type"],
    components: {
        vcLoading		    : () => import('../../components/pages/loading.vue' ),
        vcFormatPrice	    : () => import('../../components/product/format-price.vue' ),
		vcDynamicCustomLink	: () => import('../../components/pages/dynamic-custom-link.vue')
    },
    data() {
        return {
            isLoadingSearch     : null,
            searchItems		    : [],
            showSearchResults	: false,
            showViewMore	    : false,
            openSearch		    : false,
            search_text	        : ''
        }
    },
    async mounted(){
        
        this.$root.$on('charge-search-result', this.getSearchResult);
        this.$root.$on('execute-view-more', this.executeSearchCollection);
        this.$root.$on('execute-search', this.changeLoadingSearch);
    },
    computed: {
        getSearchItems(){
            return this.searchItems;
        },
        getSearchText(){
            return this.search_text;
        },
        getValidSearchResult(){
            return this.showSearchResults;
        },
        getLoadingSearch(){
            return this.isLoadingSearch;
        },
        getShowViewMore(){
            return this.showViewMore;
        }
    },
    methods: {
        changeLoadingSearch(is_loading){
            this.isLoadingSearch = is_loading;
            if(is_loading === true ){
                
                this.$root.$emit("show-results-search", true);
            }
        },
        selectSearchOption(event, navigate){
            
            this.$root.$emit('show-sidebar-search', false);
            navigate(event);
        },
        getSearchResult(search_result){
            
            if( search_result.search_text === "") {
                search_result.list = [];
            }
            this.showSearchResults  = search_result.list?.length > 0;
            this.showViewMore       = (search_result.list || []).length > search_result.per_page;
            this.searchItems        = (search_result.list || []).slice(0, search_result.per_page);
            this.search_text        = search_result.search_text;
            this.$root.$emit("show-results-search", this.showSearchResults);
        },
        executeSearchCollection(){
            
            this.$refs.searchViewMore.click();
        }
    }
}
