
import { mapGetters } from 'vuex';
export default {
	props: ['homeNavigation'],
	components:{
		navbarItem: () => import('../navbar/navbar-item.vue')
	},
	data() {
		return {
			openMobileNav: false,
			activeItem: '',
			activeChildItem: '',
			activeMegaChild: '',
			navigation: null
		}
	},
    computed: {
		...mapGetters({
			getterNavigations: 'getterNavigations'
		}),
        getNavigation(){
            
            return this.getterNavigations?.header || [];
        }
    },
    methods: {
		setNavigationType(navigation_option){
			if( navigation_option.sub_navigation_type === 'basic' ){
				return 'dropdown';
			}
			else if( navigation_option.sub_navigation_type === 'full-width' ){
				return 'mega-menu';
			}
			else if ( navigation_option.sub_navigation_type === 'container-width' ){

				return 'columns'
			}
			else{
				return 'dropdown';
			}
		},
        setActive(menuItem) {
            
            this.activeItem = this.activeItem === menuItem ? '' : menuItem;
        }
	}
}
