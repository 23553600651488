
import { mapGetters, mapActions } from "vuex";
import * as htmlToImage from 'html-to-image';
export default {
    components: {
        vcLoading               : () => import('../../../components/pages/loading.vue'),
        vcFormField		        : () => import("../../../components/pages/form-field.vue"),
        breadcrumbs             : () => import('../../../components/pages/breadcrumbs.vue'),
        vcDynamicLink           : () => import('../../../components/pages/dynamic-link.vue'),
        dynamicComponentLoader  : () => import('../../../components/pages/dynamic-component-loader.vue')
    },
    data() {
        return {
            isLoadingPage       : true,
            isLoadingAffiliate  : null,
            size                : 250,
            affiliate_data      : {
                paypal_email: null,
                email       : null,
                phone       : null
            },
            listPhoneCodes      : [],
            phoneNumber         : "",
            countryPhoneCode    : "US-1",
            selected_phone_type: [], // Must be an array reference!
            options_phone_type: [
            { text: 'Call', value: 'call' },
            { text: 'SMS', value: 'sms' },
            { text: 'Whatsapp', value: 'whatsapp' }
            ],
            error_phone_type: false
        }
    },
    async mounted() {
        
        await this.getCountries().then( async (result_countries) => {
            
            this.listPhoneCodes = this.getterCountries.phone_codes;
        }); 
        this.isLoadingPage = true;
        await this.getAffiliate().then( (result) => {
            this.isLoadingPage = null;
        }).catch( (error) => {
            this.isLoadingPage = null;
        });
        
        if( this.getterAvailableAffiliate === false ){
            this.$nextTick( () => {
                this.$refs.affiliateInfo.show();
                this.isLoadingAffiliate = true;
            });
        }
        this.$root.$on('update-field-value', this.updateAffiliateFields);
    },
    computed: {
        ...mapGetters({
            getterCountries         : 'getterCountries',
            getterAffiliate         : 'getterAffiliate',
            getterAvailableAffiliate: 'getterAvailableAffiliate'
        }),
        getLoadingPage() {
            return this.isLoadingPage;
        },
        getLoadingAffiliate() {
            return this.isLoadingAffiliate;
        },
        getAffiliateData() {
            return this.affiliate_data;
        },
        getPhoneNumber(){
            return this.phoneNumber;
        },
        getPhoneCode(){
            return this.countryPhoneCode;
        },
        getPhoneData(){
            return { phoneNumber: this.getPhoneNumber, countryPhoneCode: this.getPhoneCode };
        },
        getSelectPhoneType(){
            return this.selected_phone_type;
        },
        getErrorSelectPhoneType(){
            return this.error_phone_type;
        }
    },
    methods: {
        ...mapActions({
            getCountries: 'getCountries',
            getAffiliate: 'getAffiliate',
            updateAffiliate: 'updateAffiliate'
        }),
        downloadQRCode() {
            let node = document.getElementById('container-affiliate-qr');
            
            let affiliate_code = this.getterAffiliate?.code;
            htmlToImage.toPng(node).then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = `my-affiliate-code-${ affiliate_code }.png`;
                link.href = dataUrl;
                link.click();
            }).catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
        },
        copyAffiliateLink(){
            this.$copyText(this.getterAffiliate?.url_code);
        },
        validPhoneNumber(phone){
            
            if( phone != null ){
                
                phone = phone.replace(/([ +])+/g, "");
                
                let code = phone.replace(phone.slice(-10), "");
                
                let index_country = code === "" ? -1 : this.listPhoneCodes.findIndex( (item) => item.phone_code === parseInt(code) );
                
                let country_code = ( code != "" && code === "1" ) || index_country < 0 ? "US-1" : this.listPhoneCodes[index_country].id;
                
                console.log( country_code );
                return {
                    code    : country_code,
                    number  : phone.slice(-10),
                    format  : `+${ country_code.split("-")[1] }${ phone.slice(-10) }`
                };
            }
            else{
                
                return {
                    code    : "US-1",
                    number  : "",
                    format  : ""
                };
            }
        },
        hideModal() {
            this.$refs.affiliateInfo.hide();
        },
        async preSubmit(is_valid){
            
            console.log( is_valid );
            if( is_valid && this.getSelectPhoneType.length > 0 ){
                
                let phone = this.validPhoneNumber( `+${ this.getPhoneCode.split('-')[1] }${ this.getPhoneNumber }` );
                this.error_phone_type = false;
                let body = {
                    paypal_email    : this.getAffiliateData.paypal_email,
                    phones          : [
                        {   
                            call    : this.getSelectPhoneType.indexOf('call') >= 0,
                            sms     : this.getSelectPhoneType.indexOf('sms') >= 0,
                            whatsapp: this.getSelectPhoneType.indexOf('whatsapp') >= 0,
                            number  : phone.format
                        }
                    ],
                    social_networks : [],
                    email           : this.getAffiliateData.email
                };
                await this.updateAffiliate( body ).then( (result) => {

                    if( this.getterAvailableAffiliate ){
                        document.getElementById(`reset-form`).click();
                        this.hideModal();
                        this.isLoadingAffiliate = null;
                    }
                }).catch( (error) => {
                    console.error( error );
                });
            }
            else if( this.getSelectPhoneType.length === 0 ){
                
                this.error_phone_type = true;
            }
        },
        updateAffiliateFields(data){
            if( data.field === "email" ){
                this.affiliate_data.email = data.value;
            }
            else if( data.field === "paypal_email" ){
                this.affiliate_data.paypal_email = data.value;
            }
            else if( data.field === "phone" ){
                this.phoneNumber = data.value.phoneNumber;
                this.countryPhoneCode = data.value.countryPhoneCode;
                this.affiliate_data.phone = data.value.phoneNumber;
            }
        }
    }
}
