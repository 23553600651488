
import { mapGetters, mapActions } from "vuex";
let leadData = {
	_id 				: null,
	email				: '',
	first_name			: '',
	last_name			: '',
	phone				: '',
	address				: {
		first_name	: '',
		last_name	: '',
		name		: '',
		phone		: '',
		address_1	: '',
		address_2	: '',
		company		: '',
		country		: 'United States',
		country_code: 'US',
		state		: null,
		state_code	: null,
		city		: '',
		zip			: ''
	},
	invest_range		: "$0 to $299",
	have_experience		: true,
	physical_store 		: true,
	target_sell 		: null,
	company_webside		: null,
	have_company_webside: true,
	social_networks 	: [
	{ type: "facebook"	, user: "", value: 0 },
	{ type: "instagram"	, user: "", value: 0 },
	{ type: "tiktok"	, user: "", value: 0 }
	],
	business_size		: null,
	business_type		: null,
	terms_and_conditions: null,
	language			: 'English',
	note				: null
};
export default {
	props: ["initialState"],
	components:{
		vcLoading	: () => import('../../components/pages/loading.vue' ),
		formAddress	: () => import("../../components/pages/form-address.vue"),
		formBusiness: () => import("../../components/account/form-business.vue"),
		vcFormField	: () => import("../../components/pages/form-field.vue")
	},
	data() {
		return {
			isLoading				: null,
			currentStep				: 1,
			stepError				: false,
			effect					: "out-in-translate-fade",
			mode					: "out-in",
			
			listCountries			: [],
			listPhoneCodes			: [],
			listStates				: [],
			addressForm				: {...leadData.address, email: ""},
			defaultLead				: {...leadData},
			lead					: {...leadData},
			agent					: null,
			profile_image			: null,
			hide_profile 			: false,
			
			optionPhoneCodes		: [],
			leadPhoneCode			: "US-1",
			lead_phone				: "",
			valid_lead_phone		: "",
			
			dataCountries			: [],
			optionAddressCountries	: [],
			optionAddressStates		: [],
			
			db_country				: null,
			db_state				: null,
			
			options_invest_range	: [
			{ label: "$0 to $299"					, id: "$0 to $299" },
			{ label: "$300 to $599 (10% off)"		, id: "$300 to $599" },
			{ label: "$600 to $999 (30% off)"		, id: "$600 to $999" },
			{ label: "$1000 or more (up to 40% off)", id: "$1000" }
			],
			options_business_type 	: [],
			language				: 'English',
			step_1_data				: null
		};
	},
	async mounted() {
		
		this.options_business_type = this.getterBusinessTypes.map((item) => {
			return { label: item.name, id: item._id.toString() };
		});
		this.defaultLead.business_type = this.options_business_type[0].id;
		this.lead = {...this.defaultLead};
		await this.getCountries().then( async (result_countries) => {
			
			this.listPhoneCodes = this.getterCountries.phone_codes;
			this.listCountries 	= this.getterCountries.list;
			this.listStates     = this.getterCountries.states;
		}); 
		this.$root.$on('valid-form-business', this.validFormBusiness);
		this.$root.$on('valid-form-address', this.validFormAddress);
		this.$root.$on('update-field-value', this.updateFieldValue);
	},
	computed: {
		...mapGetters({
			getterCompanyData 	: 'getterCompanyData',
			getterLead			: "getterLead",
			getterCountries		: "getterCountries",
			getterBusinessTypes	: "getterBusinessTypes",
			getterLeadLanguages	: "getterLeadLanguages"
		}),
		getLeadAddress(){
			return this.addressForm;
		},
		getDataListCountries(){
			return this.listCountries;
		},
		getDataListStates(){
			return this.listStates;
		},
		getDataListPhoneCodes(){
			return this.listPhoneCodes;
		},
		getAgent(){
			return this.agent;
		},
		getCompanyPhoneNumberCall(){
			return this.getterCompanyData?.contact_information.phones.find( (item) => item.call ) || null;
		},
		getCompanyPhoneNumberWhatsapp(){
			return this.getterCompanyData?.contact_information.phones.find( (item) => item.whatsapp ) || null;
		},
		getCompanyPhoneNumberSMS(){
			return this.getterCompanyData?.contact_information.phones.find( (item) => item.sms ) || null;
		},
		getLeadLenaguage(){
			return this.language;
		}
	},
	methods: {
		...mapActions({
			getCountries	: 'getCountries',
			addNotification	: 'addNotification',
			createLead		: 'createLead',
			updateLead		: 'updateLead',
			addNotification	: 'addNotification'
		}),
		async validFormAddress(data_form){
			
			this.step_1_data = data_form;
			document.getElementById('submit-form-step-1').click();
		},
		async preSubmitSetp1(is_valid){
			
			if( this.step_1_data.valid && this.step_1_data.valid_zip_code && this.lead.invest_range ){
				
				try {
					const token = await this.$recaptcha.getResponse();
					
					console.log( token );
					if( token ){
						this.addressForm    	= this.step_1_data.address;
						
						this.lead.email 		= this.addressForm.email;
						this.lead.first_name 	= this.addressForm.first_name;
						this.lead.last_name 	= this.addressForm.last_name;
						this.lead.address.name 	= `${ this.lead.first_name } ${ this.lead.last_name }`;
						this.lead.phone 		= this.addressForm.phone;
						
						this.lead.address = {...this.addressForm};
						delete this.lead.address.email;
						let data_lead = {
							data: {
								firstName		: this.lead.first_name,
								lastName		: this.lead.last_name,
								email			: this.lead.email,
								phoneNumber		: this.lead.phone,
								addresses		: [this.lead.address],
								db_country		: { iso_code: this.lead.address.country_code },
								db_state		: { iso_code: this.lead.address.state_code },
								typeOfBusiness	: this.lead.business_type,
								idGoogleAdd		: this.$cookies.get('gclid') ? this.$cookies.get('gclid').value : null,
								origin			: this.$cookies.get('origin_add') ? this.$cookies.get('origin_add') : null,
								utmSource		: this.$cookies.get('utmSource') ? this.$cookies.get('utmSource') : null,
								utmMedium		: this.$cookies.get('utmMedium') ? this.$cookies.get('utmMedium') : null,
								utmCampaign		: this.$cookies.get('utmCampaign') ? this.$cookies.get('utmCampaign') : null,
								utmTerm			: this.$cookies.get('utmTerm') ? this.$cookies.get('utmTerm') : null,
								source			: this.initialState.source,
								language		: this.getLeadLenaguage,
								client_ip		: null
							},
							step: 1,
							finalStep: false
						};
						this.agent = null;
						this.isLoading = true;
						await this.createLead(data_lead).then( (result_lead) => {
							
							this.stepError = false;
							this.isLoading = null;
							if( this.getterLead.valid ){
								
								this.currentStep = 1;
								this.lead = {...this.defaultLead};
								this.agent = this.getterLead.data?.agent || null;
								if( this.agent ){
									
									if( this.agent === null || this.agent.photo === '' ){
										
										this.profile_image = `${ this.$config.hefesto_file.baseURL }/images/svg-store/svg-profile-image/svg-secondary.svg`;
									}
									else{
										
										this.profile_image = `${ this.$config.agent.baseURL.replace('/api', '') }/${ this.agent.photo.replace('public/', '') }`;
									}
									this.hide_profile = false;
									this.agent.phone = this.agent.phone ? this.agent.phone : '';
									this.agent.whatsappLink = this.agent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.agent.phone.replace(/ +/g, '').replace('+', '') }` : '';
									this.agent.smsLink = this.agent.phone != '' ? `sms:${ this.agent.phone.replace(/ +/g, '') }?body=${ encodeURI( 'I want to start buying the best Colombian girdles and jeans.' ) }` : '';
								}
								this.$root.$emit('reset-business-data', { id_form: "register-business-data", clean: true });
								
								this.showModal("modal_agent");
								// if( this.getterLead.data?.process_type === 'create' ){
									
								// 	this.showModal("modal_agent");
								// }
								// else{
									
								// 	this.showModal("modalAgent2");
								// }
								
								this.$root.$emit('reset-address', { id_form: "register-lead-address", clean: true });
								
								this.$cookies.remove("gclid");
								this.$cookies.remove("origin_add"); 
								this.$cookies.remove("utmSource"); 
								this.$cookies.remove("utmMedium");
								this.$cookies.remove("utmCampaign");
								this.$cookies.remove("utmTerm");
							}
							else{
								
								this.addNotification({ 
									content: { type: 'request' }, 
									type: "error", 
									data: { title: "Error: Register fields", message: "There are fields that have not been validated", app_version: this.$cookies.get('app-version') }
								});
							}
						});
					}
				} catch (error) {
					
					this.stepError = true;
					this.addNotification({ 
						content	: { type: 'request' }, 
						type	: 'error', 
						data	: {
							title: `Error: reCAPTCHA Validation`, 
							message: `reCAPTCHA could not be validated correctly, please try again`,
							app_version: this.$cookies.get('app-version')
						}
					});
				}
			}
			else{
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: "error", 
					data: { 
						title: "Error: Register fields", 
						message: `There are fields that have not been validated${ this.step_1_data.valid_zip_code ? '' : `, Zip Code does not belong to the state <strong>${ this.step_1_data.address.state }</strong>` }`, 
						app_version: this.$cookies.get('app-version') 
					}
				});
			}
		},
		async validFormBusiness(data_form){
			
			if( data_form.valid ){
				
				this.lead.have_experience		= data_form.lead.have_experience;
				this.lead.physical_store 		= data_form.lead.physical_store;
				this.lead.target_sell 			= data_form.lead.target_sell;
				this.lead.company_webside		= data_form.lead.company_webside;
				this.lead.have_company_webside	= data_form.lead.have_company_webside;
				this.lead.social_networks 		= data_form.lead.social_networks;
				this.lead.business_size			= data_form.lead.business_size;
				this.lead.terms_and_conditions	= data_form.lead.terms_and_conditions;
				this.lead.language				= data_form.lead.language;
				this.lead.note					= data_form.lead.note;
				
				let data_lead = {
					data		: {
						id						: this.lead._id,
						email					: this.lead.email,
						firstName				: this.lead.first_name,
						lastName				: this.lead.last_name,
						phoneNumber				: this.lead.phone,
						addresses				: [this.lead.address],
						initialBudget			: this.lead.invest_range,
						haveExperience 			: this.lead.have_experience,
						physicalStore			: this.lead.physical_store,
						companyWebsite			: this.lead.company_webside,
						socialNetwork			: this.lead.social_networks.reduce( (previous_item, current_item) => {
							if( current_item.user != '' ){
								previous_item.push({ type: current_item.type, user: current_item.user });
							}
							return previous_item;
						}, []),
						targetSell				: this.lead.target_sell 	? this.lead.target_sell 	: null,
						businessSize			: this.lead.business_size	? this.lead.business_size 	: null,
						language				: this.lead.language		? this.lead.language 		: null,
						checkTermsAndConditions	: this.lead.terms_and_conditions,
					},
					step: 2,
					finalStep: true
				};
				await this.updateLead(data_lead).then( (result_lead) => {
					
					if( this.getterLead.valid ){
						
						this.currentStep = 1;
						this.lead = {...this.defaultLead};
						this.agent = this.getterLead.data.agent;
						if( this.agent ){
							
							if( this.agent === null || this.agent.photo === '' ){
								
								this.profile_image = `${ this.$config.hefesto_file.baseURL }/images/svg-store/svg-profile-image/svg-secondary.svg`;
							}
							else{
								
								this.profile_image = `${ this.$config.agent.baseURL.replace('/api', '') }/${ this.agent.photo.replace('public/', '') }`;
							}
							this.hide_profile = false;
							this.agent.phone = this.agent.phone ? this.agent.phone : '';
							this.agent.whatsappLink = this.agent.phone != '' ? `https://api.whatsapp.com/send?phone=${ this.agent.phone.replace(/ +/g, '').replace('+', '') }` : '';
							this.agent.smsLink = this.agent.phone != '' ? `sms:${ this.agent.phone.replace(/ +/g, '') }?body=${ encodeURI( 'I want to start buying the best Colombian girdles and jeans.' ) }` : '';
						}
						this.$root.$emit('reset-business-data', { id_form: "register-business-data", clean: true });
						
						this.showModal("modal_agent");
						
						this.$root.$emit('reset-address', { id_form: "register-lead-address", clean: true });
					}
					else{
						
						this.stepError = true;
						this.addNotification({ 
							content: { type: 'request' }, 
							type: "error", 
							data: { title: "Error: Register fields", message: "There are fields that have not been validated", app_version: this.$cookies.get('app-version') }
						});
					}
				});
			}
			else{
				
				this.stepError = true;
				this.addNotification({ 
					content: { type: 'request' }, 
					type: "error", 
					data: { title: "Error: Register fields", message: "There are fields that have not been validated", app_version: this.$cookies.get('app-version') }
				});
			}
		},
		updateFieldValue(data){
			
			if( this.lead[data.field] ){
				
				this.lead[data.field] = data.value;
			}
		},
		stepNumberClasses(this_step) {
			return {
				"step-orange text-white"	: ( this.currentStep === this_step && !this.stepError ),
				"step-success text-white"	: ( this.currentStep > this_step && !this.stepError ) || ( this.stepError && this.currentStep > this_step ),
				"step-error text-white" 	: this.stepError && this.currentStep === this_step
			};
		},
		goBackStep() {
			
			document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-in");
			document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-out");
			
			setTimeout(() => {
				this.currentStep--;
				
				document.getElementById(`step-${ this.currentStep }`).classList.add("step-form-animation-out");
				document.getElementById(`step-${ this.currentStep }`).classList.remove("step-form-animation-in");
				
			}, 120);
			
			this.$root.$emit('change-select-address', { id_form: "register-lead-address", address: {...this.lead.address, email: this.lead.email}, type: "lead-fields" });
			this.$root.$emit('move-custom-form', true);
		},
		goNextStep() {
			
			if( this.currentStep === 1 ){
				
				this.$root.$emit('valid-address', { id_form: "register-lead-address", action: "register-lead-address" });
			}
			else if( this.currentStep === 2 ){
				
				this.$root.$emit('valid-business-data', { id_form: "register-business-data", action: "register-business-data" });
			}
		},
		showModal(refName) {
			this.$refs[refName].show();
		},
		hideModal(refName) {
			
			this.$refs[refName].hide();
		},
		goInitForm(){
			this.hideModal('modal_step_1');
			this.$root.$emit('move-custom-form', true);
		}
	}
};
