
import { mapGetters } from 'vuex';
export default {
	props: ["store_info"],
	components: {
        dynamicComponentLoader  : () => import('../../components/pages/dynamic-component-loader.vue'),
		vcDynamicLink			: () => import('../../components/pages/dynamic-link.vue')
	},
	data() {
		return {
			store_information: this.store_info,
			store_path: this.$route.path
		}
	},
	async mounted(){
		this.$root.$on('update-store-information', this.updateStoreInformation);
	},
	computed: {
		...mapGetters({
			getterBasicData: 'getterBasicData',
			getterNavigations: 'getterNavigations'
		}),
		getStorePath(){
			return this.store_path;
		},
		getAllPhones(){
			return ( this.getterBasicData?.contact_information.phones || [] ).reduce( (previous_item, current_item, current_index, original_array) =>{
				
				if( current_item.call ){
					previous_item.push({ calls: true, number: current_item.number, url: current_item.url });
				}
				if( current_item.sms ){
					previous_item.push({ sms: true, number: current_item.number, url: current_item.url });
				}
				if( current_item.whatsapp ){
					previous_item.push({ whatsapp: true, number: current_item.number, url: current_item.url });
				}
				if( current_index === original_array.length - 1 ){
					previous_item = previous_item.filter( (item) => item.call ).concat( previous_item.filter( (item) => item.sms ) ).concat( previous_item.filter( (item) => item.whatsapp ) );
				}
				return previous_item;
			}, []);
		}
	},
	methods: {
		updateStoreInformation(storeInfoData){
			
			this.store_information = storeInfoData;
		}
	}
}
