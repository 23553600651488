
export default {
    name: 'IconSalesAgent',
    props: {
        color: {
            type: String,
            default: '#303030'
        }
    }
}
