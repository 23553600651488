
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        sliderVideo			    : () => import('../../components/home/slider-video.vue'),
        productCollection	    : () => import('../../components/product/product-collection.vue'),
        logoSlider			    : () => import('../../components/home/logo-slider.vue'),
        faq					    : () => import('../../components/account/faq.vue'),
        vcDynamicLink		    : () => import('../../components/pages/dynamic-link.vue'),
        vcCountUp		        : () => import('../../components/pages/countup.vue'),
        testimonial			    : () => import('../../components/home/testimonial.vue'),
        dynamicComponentLoader  : () => import('../../components/pages/dynamic-component-loader.vue'),
    },
    data() {
        return {
            isLoading					: true,
            collection_products			: [],
            bussines_image        : {
                src     : '/images/home/bussines-image_full.webp',
                sizes   : [
                '( min-width: 1280px ) 900px',
                '( min-width: 1024px ) 500px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 320px',
                '( min-width: 320px ) 300px',
                '300px'
                ],
                srcset  : [
                '/images/home/bussines-image_full.webp 900w',
                '/images/home/bussines-image_500.webp 500w',
                '/images/home/bussines-image_500.webp 400w',
                '/images/home/bussines-image_400.webp 320w',
                '/images/home/bussines-image_400.webp 300w'
                ]
            },
            benefits_image_1         : {
                src     : '/images/home/cata1og-benefits-1_full.webp',
                sizes   : [
                '( min-width: 1280px ) 900px',
                '( min-width: 1024px ) 500px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 320px',
                '( min-width: 320px ) 300px',
                '300px'
                ],
                srcset  : [
                '/images/home/cata1og-benefits-1_300.webp 300w',
                '/images/home/cata1og-benefits-1_320.webp 320w',
                '/images/home/cata1og-benefits-1_400.webp 400w',
                '/images/home/cata1og-benefits-1_500.webp 500w',
                '/images/home/cata1og-benefits-1_full.webp 900w'
                ]
            },
            benefits_image_2         : {
                src     : '/images/home/cata1og-benefits-2_full.webp',
                sizes   : [
                '( min-width: 1280px ) 900px',
                '( min-width: 1024px ) 500px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 320px',
                '( min-width: 320px ) 300px',
                '300px'
                ],
                srcset  : [
                '/images/home/cata1og-benefits-2_300.webp 300w',
                '/images/home/cata1og-benefits-2_320.webp 320w',
                '/images/home/cata1og-benefits-2_400.webp 400w',
                '/images/home/cata1og-benefits-2_500.webp 500w',
                '/images/home/cata1og-benefits-2_full.webp 900w'
                ]
            },
            cateogry_product_image_1: {
                src     : '/images/home/product-category-jeans_full.webp',
                sizes   : [
                '( min-width: 1536px ) 350px',
                '( min-width: 1280px ) 320px',
                '( min-width: 1024px ) 280px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 250px',
                '( min-width: 320px ) 180px',
                '350px'
                ],
                srcset  : [
                '/images/home/product-category-jeans_400.webp 400w',
                '/images/home/product-category-jeans_full.webp 350w',
                '/images/home/product-category-jeans_320.webp 320w',
                '/images/home/product-category-jeans_280.webp 280w',
                '/images/home/product-category-jeans_250.webp 250w',
                '/images/home/product-category-jeans_180.webp 180w'
                ]
            },
            cateogry_product_image_2: {
                src     : '/images/home/product-category-shapewear_full.webp',
                sizes   : [
                '( min-width: 1536px ) 350px',
                '( min-width: 1280px ) 320px',
                '( min-width: 1024px ) 280px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 250px',
                '( min-width: 320px ) 180px',
                '350px'
                ],
                srcset  : [
                '/images/home/product-category-shapewear_400.webp 400w',
                '/images/home/product-category-shapewear_full.webp 350w',
                '/images/home/product-category-shapewear_320.webp 320w',
                '/images/home/product-category-shapewear_280.webp 280w',
                '/images/home/product-category-shapewear_250.webp 250w',
                '/images/home/product-category-shapewear_180.webp 180w'
                ]
            },
            cateogry_product_image_3: {
                src     : '/images/home/product-category-underwear_full.webp',
                sizes   : [
                '( min-width: 1536px ) 350px',
                '( min-width: 1280px ) 320px',
                '( min-width: 1024px ) 280px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 250px',
                '( min-width: 320px ) 180px',
                '350px'
                ],
                srcset  : [
                '/images/home/product-category-underwear_400.webp 400w',
                '/images/home/product-category-underwear_full.webp 350w',
                '/images/home/product-category-underwear_320.webp 320w',
                '/images/home/product-category-underwear_280.webp 280w',
                '/images/home/product-category-underwear_250.webp 250w',
                '/images/home/product-category-underwear_180.webp 180w'
                ]
            },
            cateogry_product_image_4: {
                src     : '/images/home/product-category-accessories_full.webp',
                sizes   : [
                '( min-width: 1536px ) 350px',
                '( min-width: 1280px ) 320px',
                '( min-width: 1024px ) 280px',
                '( min-width: 768px ) 400px',
                '( min-width: 640px ) 250px',
                '( min-width: 320px ) 180px',
                '350px'
                ],
                srcset  : [
                '/images/home/product-category-accessories_400.webp 400w',
                '/images/home/product-category-accessories_full.webp 350w',
                '/images/home/product-category-accessories_320.webp 320w',
                '/images/home/product-category-accessories_280.webp 280w',
                '/images/home/product-category-accessories_250.webp 250w',
                '/images/home/product-category-accessories_180.webp 180w'
                ]
            },
            swipper_services_options: {
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                    },
                    768: {
                        slidesPerView: 3
                    },
                    640: {
                        slidesPerView: 2
                    }
                }
            },
            swipper_goals_options: {
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4
                    },
                    768: {
                        slidesPerView: 2
                    },
                    640: {
                        slidesPerView: 1
                    }
                }
            },
            swipper_products_options: {
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay: {
                    delay: 12000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4
                    },
                    991: {
                        slidesPerView: 3,
                        loop: true
                    },
                    768: {
                        slidesPerView: 2,
                        loop: true
                    },
                    640: {
                        slidesPerView: 1,
                        loop: true
                    }
                }
            },
            swipper_products_categories: {
                slidesPerView: 2,
                spaceBetween: 0,
                autoplay: {
                    delay: 4000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4
                    },
                    991: {
                        slidesPerView: 3
                    },
                    768: {
                        slidesPerView: 2
                    },
                    640: {
                        slidesPerView: 2
                    }
                }
            },
            execute_counts_up: false
        }
    },
    async mounted() {
        window.addEventListener('load', this.loadPage);
        window.addEventListener('scroll', this.loadPage);
        
        await this.getCollectionHome();
    },
    computed: {
        ...mapGetters({
            getterCollectionHome: 'getterCollectionHome',
            getterBasicData: 'getterBasicData',
            getterHomeMetrics: 'getterHomeMetrics'
        }),
        getterCallPhone(){
            return this.getterBasicData?.contact_information.phones.find( (item) => item.call );
        }
    },
    methods: {
        ...mapActions({
            getCollectionHome: 'getCollectionHome'
        }),
        loadPage(event){ 
            const targetSection = document.getElementById('section-goals');
            const rect = targetSection.getBoundingClientRect();
            const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;
            
            if (this.execute_counts_up === false && isVisible) {
                console.log('Target section is visible');
                this.$root.$emit("init-count-up", 'count-up-customers');
                this.$root.$emit("init-count-up", 'count-up-orders');
                this.$root.$emit("init-count-up", 'count-up-brands');
                this.$root.$emit("init-count-up", 'count-up-operate');
                this.execute_counts_up = true;
            }
        }
    }
}
