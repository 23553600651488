
import { mapGetters, mapActions } from "vuex";
export default {
    layout: 'only-content',
    components: {
        vcLoading		: () => import('../../components/pages/loading.vue'),
        agentProfile	: () => import('../../components/account/agent-profile.vue')
    },
    data() {
        return {
            title_page: 'Cata1og.com | Sales Executive',
            isLoading: true
        }
    },
    async mounted() {
        
        setTimeout(async () => {
            await this.getSalesAgent({ id_handle: this.$route.params.handle }).then( (result) =>{

                this.isLoading = false;
                this.title_page = `Cata1og.com | Sales Executive - ${ this.getterAgent?.name }`;
            });
        }, 500);
    },
    computed:{
        ...mapGetters({
            getterBasicData: 'getterBasicData',
            getterAgent: 'getterAgent'
        }),
        getLoading(){
            return this.isLoading;
        },
        getTitlePage(){
            return this.title_page;
        }
    },
    methods: {
        ...mapActions({
            getSalesAgent: 'getSalesAgent'
        }),
    },
	head() {
		return {
			title: this.getTitlePage
		}
	}
}
