
export default {
    props: {
        label: {
            type: String,
            default: ''
        }, 
        field_id: {
            type: String,
            default: ''
        }, 
        field_name: {
            type: String,
            default: ''
        }, 
        field_type: {
            type: String,
            default: 'text'
        },
        field_value: {
            default: ''
        }, 
        valid_rules: {
            type: String,
            default: null
        },
        select_data: {
        }
    },
    data() {
        return {
            local_field_value   : this.field_value,
            local_select_data   : this.select_data,
            field_focus         : false,
            country_code_address: 'US',
            state_code_address  : 'AL',
            googel_autocomplete : null
        };
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                    event.target.value = event.target.value.replace(/[^0-9]/g, '')
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        return true
                    }
                    event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                    if (event.charCode >= 48 && event.charCode <= 57) {
                        event.preventDefault()
                    }
                    return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    async mounted(){
        
        this.$root.$on('update-country-address', this.updateCountryCodeAddress);
        this.$root.$on('update-state-address', this.updateStateCodeAddress);
        if( this.field_id === 'address_1' && this.field_type === 'address' ){
            this.initAutocomplete();
        }
    },
    computed: {
        getFieldFocus(){
            return this.field_focus;
        },
        getFieldValue(){
            return this.local_field_value;
        },
        getSelectData(){
            return this.local_select_data;
        },
        getCountryCodeAddress(){
            return this.country_code_address;
        },
        getStateCodeAddress(){
            return this.state_code_address;
        }
    },
    methods: {
        initAutocomplete() {
            this.googel_autocomplete = new google.maps.places.Autocomplete( document.querySelector(`#${ this.field_id }`), {
                fields: ["address_components", "geometry"],
                types: ["address"],
            } );
            this.googel_autocomplete.addListener("place_changed", this.selectGoogelAddress);
        },
        selectGoogelAddress() {
            this.local_field_value  = "";
            let address1            = "";
            let google_address      = {
                street_number               : "",
                subpremise                  : "",
                route                       : "",
                locality                    : "",
                administrative_area_level_1 : "",
                country                     : "",
                postal_code                 : "",
                postal_code_suffix          : ""
            };
            for (const component of (this.googel_autocomplete.getPlace()?.address_components || [])) {
                // @ts-ignore remove once typings fixed
                const componentType = component.types[0];
                
                switch (component.types[0]) {
                    case "street_number": {
                        google_address.street_number = component.long_name;
                        break;
                    }
                    case "subpremise": {
                        google_address.subpremise = component.short_name;
                        break;
                    }
                    case "route": {
                        google_address.route = component.short_name;
                        break;
                    }
                    case "locality":{
                        google_address.locality = component.long_name;
                        break;
                    }
                    case "administrative_area_level_1": {
                        google_address.administrative_area_level_1 = component.short_name;
                        break;
                    }
                    case "country":{
                        google_address.country = component.short_name;
                        break;
                    }
                    case "postal_code": {
                        google_address.postal_code = component.long_name;
                        break;
                    }
                    case "postal_code_suffix": {
                        google_address.postal_code_suffix = component.long_name;
                        break;
                    }
                }
            }
            let new_address_value                   = `${ google_address.street_number != '' ? `${ google_address.street_number }, ` : '' }${ google_address.route }${ google_address.subpremise != '' ? ` ${ google_address.subpremise }` : '' }`;
            this.local_field_value                  = new_address_value;
            this.$refs[this.field_id].$el._value    = new_address_value;
            
            this.$root.$emit('update-field-value', { field: 'google_country_state_code', value: { country_code: google_address.country, state_code: google_address.administrative_area_level_1 } });
            this.$root.$emit('update-field-value', { field: 'city', value: google_address.locality });
            this.$root.$emit('update-field-value', { field: 'zip', value: `${ google_address.postal_code }` });
            this.$root.$emit('update-field-value', { field: 'address_1', value: new_address_value });
            
        },
        updateFieldValue(value){
            
            this.$root.$emit('update-field-value', { field: this.field_id, value: value });
        },
        updateFieldMultiValue(index, value){

            let selected_values = this.local_select_data.map( (item, index_item) => {
                
                return index === index_item ? value : item;
            });
            this.$root.$emit('update-field-value', { field: this.field_id, value: selected_values });
        },
        updateFieldFocus(value){
            this.field_focus = value;
        },
        updateCountryCodeAddress(contry_code){
            this.country_code_address = contry_code;
        },
        updateStateCodeAddress(contry_code){
            this.state_code_address = contry_code;
        }
    },
    watch: {
        field_value(value) {
            this.local_field_value = value;
        },
        select_data(value) {
            this.local_select_data = value || [];
        }
    }
}
