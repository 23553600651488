
export default {
	props: {
		'type'			: { type: String, default: 'pages' },
		'title'			: { type: String, default: null }, 
		'subtitle'		: { type: String, default: null },
		'subItemData'	: { type: Object, default: null }
	}, 
    components: {
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
    },
	mounted(){
	}
}
