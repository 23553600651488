
export default {
	components: {
		vcDynamicLink	: () => import('../../../../components/pages/dynamic-link.vue')
	},
	data() {
		return {
			result  : null, 
			title   : null, 
			message : null, 
			error   : null
		}
	},
	mounted(){
	},
	created(){
		this.result   = decodeURI( this.$route.query.result );
		this.title    = decodeURI( this.$route.query.title );
		this.message  = decodeURI( this.$route.query.message );
		this.error    = decodeURI( this.$route.query.error );
	}
}
