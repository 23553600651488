
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        profileImage	        : () => import('../account/profile-image.vue'),
        dynamicComponentLoader  : () => import('../pages/dynamic-component-loader.vue'),
        vcDynamicCustomLink	    : () => import('../../components/pages/dynamic-custom-link.vue')
    },
    mounted() {
        this.$root.$on('show-sidebar-account', this.showSidebarAccount);
    },
    computed: {
        ...mapGetters({
            getterBasicData     : 'getterBasicData',
            getterNavigations   : 'getterNavigations',
            getterUser          : 'getterUser',
            getterCustomer      : 'getterCustomer',
            getterLevel         : 'getterLevel',
        }),
        getNavigationSection1(){
            return ( this.getterNavigations?.account || [] ).slice(0, ( this.getterNavigations?.account || [] ).length - 2 );
        },
        getNavigationSection2(){
            return ( this.getterNavigations?.account || [] ).slice(( this.getterNavigations?.account || [] ).length - 2, ( this.getterNavigations?.account || [] ).length );
        }
    },
    methods: {
        ...mapActions({
            logoutUser			: 'logoutUser',
        }),
        showSidebarAccount(is_show){
            if (is_show) {
                this.$refs.sidebarAccount.show();
            } else {
                this.$refs.sidebarAccount.hide();
            }
        },
        closeSidebarAccount(event, navigate){
            
            this.$refs.sidebarAccount.hide();
            navigate(event);
        },
        logout() {
            this.$refs.sidebarAccount.hide();
            this.logoutUser();
            this.$router.push('/account/login');
        }
    }
}
