
import { mapGetters, mapActions } from 'vuex'
export default {
    components: {
        vcLoading       : () => import('../../../components/pages/loading.vue'),
        breadcrumbs     : () => import('../../../components/pages/breadcrumbs.vue'),
        agentProfile    : () => import('../../../components/account/agent-profile.vue'),
		vcDynamicLink	: () => import('../../../components/pages/dynamic-link.vue')
    },
    data() {
        return {
            isLoading       : true,
            profile_image   : '',
            hide_profile    : true
        };
    },
    async mounted(){
        await this.getCustomerAgent().then( (result_agent) => {
            
            this.isLoading = null;
        });
    },
    computed: {
        ...mapGetters({
            getterCustomerAgent: 'getterCustomerAgent'
        }),
    },
    methods:{
        ...mapActions({
            getCustomerAgent: "getCustomerAgent"
        })
    }
}
