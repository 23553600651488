
import { mapGetters } from "vuex";
export default {
    props: ['agentData', 'layout'],
    components: {
        vcLoading		        : () => import('../../components/pages/loading.vue' ),
        dynamicComponentLoader  : () => import('../../components/pages/dynamic-component-loader.vue')
    },
    data() {
        return {
            SMSPhone    : null,
            mainLocation: null,
            selectAgent : null,
        }
    },
    async mounted() {
        
        for (const item_phone of ( this.getterBasicData?.contact_information.phones || [])) {
            if( item_phone.sms ){
                
                this.SMSPhone = item_phone;
            }
            if( item_phone.call && ['', null].includes( this.agentData?.phone ) ){
                
                this.agentData.phone = item_phone.number;
            }
        }
        this.agentData.phone = this.agentData.phone.replace(/ /g, '');

        this.selectAgent = this.agentData;
        for (const item_location of ( this.getterBasicData?.contact_information.locations || [])) {
            if( item_location.main ){
                
                this.mainLocation = item_location;
            }
        }
    },
    computed:{
        ...mapGetters({
            getterBasicData: 'getterBasicData'
        }),
        getSMSPhone(){
            return this.SMSPhone;
        },
        getMainLocation(){
            return this.mainLocation;
        },
        getAgentData(){
            return this.selectAgent;
        }
    },
    methods: {
    }
}
