
import { mapGetters, mapActions } from "vuex";
import { effectSlider, randomNumber }  from '../../constants/config.js';
export default {
	props:["banner", "title", "message", "source"],
	components: {
		logoSlider 		: () => import( '../../components/home/logo-slider.vue' ),
		stepRegister	: () => import( '../../components/account/step-register.vue' ),
		faq         	: () => import( '../../components/account/faq.vue' ),
		testimonial		: () => import('../../components/home/testimonial.vue'), 
	},
	data() {
		return {
			products: [],
			category: [],
			showquickviewmodel: false,
			showcomparemodal: false,
			showcartmodal: false,
			quickviewproduct: {},
			comapreproduct: {},
			cartproduct: {},
			imagePath: `/images${ this.banner }`,
			position_form: 0
		}
	},
	async mounted() {
		window.addEventListener('load', this.loadPage);
		this.$root.$on('move-custom-form', this.moveScrollRegisterForm );
	},
	methods: {
		...mapActions({
			getReviews: "getReviews"
		}),
		loadPage(event){
			setInterval(() => {
				effectSlider('slider-register-benefits', 3, 3000 );
			}, 6000);
		},
		moveScrollRegisterForm(valid_move) {
			if (process.client) {
				this.position_form = window.scrollY + document.getElementById('register_form').getBoundingClientRect().top - ( window.innerWidth <= 768 ? 89 : 150 );
				
				this.$nextTick(() => {
					
					setTimeout(() => {
						window.scrollTo({
							top: Math.round( this.position_form >= 0 ? this.position_form : ( this.position_form ) * -1 ),
							left: 0,
							behavior: "smooth"
						});
					}, 300);
				});
			}
		}
	}
}
