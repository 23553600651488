
import { formatData }  from '../../constants/config.js';
export default {
    props: {
        idItem: {
            type: String,
            default: ''
        },
        addText: {
            type: String,
            default: ''
        },
        maxNumber: {
            type: Number,
            default: 0
        }
    },
	data() {
		return {
			max_number		: {
                number: 0,
                format: '$ 0'
            },
			count_up_end	: false,
            init_animation	: false
		};
	},
	async mounted() {
		
		this.$root.$on("init-count-up", this.initCountUp);
	},
	computed: {
		getMaxNumber(){
			return this.max_number;
		},
        getNumberIncrement(){

            if( this.maxNumber - this.getMaxNumber.number >= 10000 ){
                return 1000;
            }
            if( this.maxNumber - this.getMaxNumber.number >= 1000 ){
                return 100;
            }
            else if( this.maxNumber - this.getMaxNumber.number >= 100 ){
                return 10;
            }
            else if( this.maxNumber - this.getMaxNumber.number >= 10 ){
                return 1;
            }
            else{
                return 1;
            }
        },
        getNumberInterval(){

            if( this.maxNumber - this.getMaxNumber.number >= 10000 ){
                return 1;
            }
            if( this.maxNumber - this.getMaxNumber.number >= 1000 ){
                return 10;
            }
            else if( this.maxNumber - this.getMaxNumber.number >= 100 ){
                return 10;
            }
            else if( this.maxNumber - this.getMaxNumber.number >= 10 ){
                return 100;
            }
            else{
                return 100;
            }
        },
        getEndCountUp(){
            return this.count_up_end;
        },
        getInitAnimation(){
            return this.init_animation;
        }
	},
	methods: {
		initCountUp(id_item){

			if( this.idItem === id_item ){

                this.init_animation = true;
                this.excuteCountUp();
            }
		},
		excuteCountUp() {
			
			if( this.getMaxNumber.number < this.maxNumber ){
				
				this.max_number = formatData.currency(this.getMaxNumber.number + this.getNumberIncrement);
                
                setTimeout(this.excuteCountUp, this.getNumberInterval);
			}
			else{
				if( !this.count_up_end ){
					
                    this.init_animation = false;
					this.count_up_end = true;
					this.$root.$emit('end-count-up', { id: this.id_item});
				}
			}
		}
	}
};
