
import { mapGetters } from 'vuex'
export default {
	props: ["layout"],
	components: {
	},
	data() {
		return {
		}
	},
	mounted() {

	},
	computed: {
		...mapGetters({
			getterLogin		: 'getterLogin',
			getterCart		: 'getterCart'
		})
	},
	methods: {
		openWidgetSearch(event){
			this.$root.$emit('show-sidebar-search', true);
		},
		openSidebarCart(event){
			event.preventDefault();
			this.$root.$emit('show-sidebar-cart', true);
		}
	}
}
