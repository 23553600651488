
export default {
    props: {
        to: {
            type: [String, Object],
            required: true
        },
        custom: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        computedTo() {
            let basePath = '';
            if (typeof this.to === 'string') {
                return `${basePath}${this.to}`;
            } else if (typeof this.to === 'object' && this.to.path) {
                return {
                    ...this.to,
                    path: `${basePath}${this.to.path}`
                };
            }
            return this.to;
        }
    }
};
