
import { mapGetters, mapActions } from "vuex";
import { formatData } from "../../constants/config.js";
export default {
	components: {
		vcLoading	: () => import('../../components/pages/loading.vue' ),
		creditCard	: () => import('../../components/payment/credit-card.vue'),
		formAddress	: () => import("../../components/pages/form-address.vue"),
	},
	data() {
		return {
			
			typeAmount		: 'balance',
			validOption		: false,
			amountBalance	: 0,
			amountPastDue	: 0,
			amountValue		: 0,
			availableOptionPay: false,
			customer		: null,
			
			listPhoneCodes	: [],
			listCountries	: [],
			listStates		: [],
			
			paymentMethod	: "credit-card",
			
			billingAddress	: {
				id			: "new-address",
				first_name	: '',
				last_name	: '',
				name		: "",
				phone		: '',
				address_1	: '',
				address_2	: '',
				company		: '',
				country		: 'United States',
				country_code: 'US',
				state		: null,
				state_code	: null,
				city		: '',
				zip			: ''
			},
			
			creditCard		: null,
			
			isLoading		: true,
			typecart		: null,
		};
	},
	async mounted() {
		if ( this.$route.query.customer || this.$route.query.id ) {
			
			this.$root.$on('valid-form-address', this.validFormAddress);
			this.$root.$on('change-credit-card', this.changeCreditCard);
			await this.getCountries().then( async (result_countries) => {
				
				this.listPhoneCodes = this.getterCountries.phone_codes;
				this.listCountries 	= this.getterCountries.list;
				this.listStates     = this.getterCountries.states;
				
				await this.getCustomer({ id_email: this.$route.query.customer || this.$route.query.id, origin: "landing" }).then( async (result_customer) => {
					
					await this.getStatementByCustomer({ id: this.getterCustomerLanding._id }).then( (result_statement) => {
						
						this.validOption 	= true;
						this.amountBalance 	= this.getterSatement.balance < 0 ? this.getterSatement.balance * -1 : 0; 
						this.amountValue 	= this.amountBalance;
						this.amountPastDue 	= this.getterSatement.balanceExpired;
						
						this.isLoading = false;
					});
				});
				this.$root.$emit('change-select-address', { id_form: "balance-billing-address", address: this.billingAddress });
			});
		}
		else{
			
			this.$router.push("/pages/401");
		}
	},
	computed: {
		...mapGetters({
			getterCustomerLanding	: "getterCustomerLanding",
			
			getterSatement			: "getterSatement",
			
			getterStatusPayBalance	: "getterStatusPayBalance",
			getterCountries			: "getterCountries",
		}),
		getBillingAddress(){
			return this.billingAddress ? this.billingAddress : this.defaultAddress;
		},
		getDataListCountries(){
			return this.listCountries;
		},
		getDataListStates(){
			return this.listStates;
		},
		getDataListPhoneCodes(){
			return this.listPhoneCodes;
		},
		getTypeAmount(){
			return this.typeAmount;
		},
		getPaymentMethod(){
			return this.paymentMethod;
		},
	},
	methods: {
		...mapActions({
			getCountries			: 'getCountries',
			getCustomer				: 'getCustomer',
			getStatementByCustomer	: 'getStatementByCustomer',
			payBalance				: 'payBalance',
			addNotification			: 'addNotification'
		}),
		async validFormAddress(data_form){
			
			if( data_form.id_form === "balance-billing-address" ){
				
				this.billingAddress 		= data_form.address;
				this.billingAddress.name	= `${ this.billingAddress.first_name } ${ this.billingAddress.last_name }`;
				if( this.paymentMethod === 'credit-card' ){
					
					this.$root.$emit('valid-credit-card-fields');
				}
				if( this.amountValue > 0 && !this.availableOptionPay && data_form.valid && ( this.paymentMethod !== 'credit-card' || ( this.paymentMethod === 'credit-card' && this.creditCard ) ) ){
					
					await this.executePayBalance();
				}
				else{
					
					this.addNotification({ 
						content: { type: 'request' }, 
						type: "error", 
						data: { title: "Error: Payment and Billing fields", message: "There are fields that have not been validated", app_version: this.$cookies.get('app-version') }
					});
				}
			}
		},
		updateMethodPay() {
			
			if(this.getPaymentMethod !== 'credit-card' ) {
				
				this.$root.$emit('clean-credit-card');
			}
		},
		changeAmountPay() {
			
			if(this.typeAmount === 'balance') {
				
				this.amountValue = this.amountBalance;
			} 
			else if(this.typeAmount === 'pastdue') {
				
				this.amountValue = this.amountPastDue;
			} 
			else if(this.typeAmount === 'custom-amount') {
				
				this.amountValue = 0;
			}
		},
		formatCurrency(price) {
			
			return formatData.currency(price);
		},
		isNumber(event, input_id){
			
			let keyCode = (event.keyCode ? event.keyCode : event.which);
			if ( ( (keyCode < 48 || keyCode > 57) && event.key != "." ) || parseInt(document.getElementById(input_id).value) === 0 && parseInt(event.key) === 0 ) {
				
				event.preventDefault();
			}
		},
		validNumber(number){
			return ( isNaN( parseInt(number) ) ? 0 : parseInt(number) );
		},
		formatNumber(n) {
			return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
		},
		formatAmount(input, blur) {
			input = document.getElementById(input);
			let input_val = input.value;
			
			if (input_val === "") { return; }
			
			let original_len = input_val.length;
			let caret_pos = input.selectionStart;
			
			if (input_val.indexOf(".") >= 0) {
				
				let decimal_pos = input_val.indexOf(".");
				let left_side = input_val.substring(0, decimal_pos);
				let right_side = input_val.substring(decimal_pos);
				
				left_side = this.formatNumber(left_side);
				right_side = this.formatNumber(right_side);
				if (blur === "blur") {
					right_side += "00";
				}
				
				right_side = right_side.substring(0, 2);
				
				input_val = "$" + left_side + "." + right_side;
				
			} 
			else {
				input_val = this.formatNumber(input_val);
				input_val = "$" + input_val;
				
				if (blur === "blur") {
					input_val += ".00";
				}
			}
			input.value = input_val;
			
			this.amountValue = parseFloat( input_val.replace('$', '').split(',').join("") );
			
			let updated_len = input_val.length;
			caret_pos = updated_len - original_len + caret_pos;
			input.setSelectionRange(caret_pos, caret_pos);
		},
		changeAmount(input_id){
			
			document.getElementById(input_id).value = this.validNumber(document.getElementById(input_id).value);
		},
		changeCreditCard(data_change){
			
			this.creditCard = data_change.valid ? data_change.credit_card : null;
		},
		processPayBalance() {
			
			this.isLoading = false;
			if (this.getterStatusPayBalance.result === "approved") {
				
				window.location.href = this.getterStatusPayBalance.url;
			}
			else{
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: "Error: Pay Balance", message: this.getterStatusPayBalance.message, app_version: this.$cookies.get('app-version') } 
				});
			}
		},
		async executePayBalance(){
			
			if (this.getterCustomerLanding) {
				
				this.isLoading = true;
				let bodyPay = {
					user_id						: this.getterCustomerLanding._id.toString(),
					customer_id					: this.getterCustomerLanding._id,
					customer_sh_id				: this.getterCustomerLanding.shopify_id,
					amount						: this.amountValue,
					"payment-method"			: this.paymentMethod,
					"card-number"				: ['credit-card'].includes( this.paymentMethod ) ? this.$encrypt(this.creditCard.cardNumber) : null,
					"card-date"					: ['credit-card'].includes( this.paymentMethod ) ? this.$encrypt(`${ parseInt( this.creditCard.cardMonth ) > 9 ? this.creditCard.cardMonth : `0${ this.creditCard.cardMonth }` }/${ this.creditCard.cardYear.toString().slice(-2) }`) : null,
					"card-verification-value"	: ['credit-card'].includes( this.paymentMethod ) ? this.$encrypt(this.creditCard.cardCvv) : null,
					"card-name"					: ['credit-card'].includes( this.paymentMethod ) ? this.$encrypt(this.creditCard.cardName) : null,
					"address-line"				: this.billingAddress.address_1,
					"address-country"			: this.billingAddress.country,
					"address-state"				: this.billingAddress.state,
					"address-city"				: this.billingAddress.city,
					"address-zipcode"			: this.billingAddress.zip,
					originPayment				: "Payment Balance Cata1og",
					resumeBuy					: null,
					product_origin				: 'balance'
				};
				await this.payBalance({ payment: bodyPay }).then( (result_pay) => {
					
					this.processPayBalance();
				}).catch( (error_pay) => {
					
					this.processPayBalance();
				});
			}
			else {
				
				this.$router.push("/account/login");
			}
		},
		submitPayBalance(){
			
			this.$root.$emit('valid-address', { id_form: "balance-billing-address", action: "pay-balance" });
		}
	}
};
