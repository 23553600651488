
import { mapGetters } from 'vuex';
export default {
	props: ["type_logo"],
	components: {
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
	},
	data() {
		return {
			show_mobile_carousel: false,
			swipper_options: {
				slidesPerView: 2,
				spaceBetween: 0,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false
				},
				loop: true,
				breakpoints: {
					1440: {
						slidesPerView: 6,
					},
					1024: {
						slidesPerView: 4,
					},
					768: {
						slidesPerView: 3,
					},
					640: {
						slidesPerView: 2,
					}
				}
			},
			logo_sizes: [
				"xs:120px",
				"sm:150px"
			]
		}
	},
	mounted(){
		this.show_mobile_carousel = window.innerWidth <= 1200;
		window.addEventListener('load', this.resizewindow);
		window.addEventListener('resize', this.resizewindow);
	},
	computed:{
		...mapGetters({
			getterSlideshows	: 'getterSlideshows',
		}),
		getSlideshow(){
			
			let slideshow = this.getterSlideshows?.brands ? this.getterSlideshows.brands : null;
			
			return slideshow != null ? slideshow.slides.filter( (item) => item.show === true ) : [];
		},
		getShowMobileCarousel(){
			return this.show_mobile_carousel;
		}
	},
	methods: {
		resizewindow(e){
			this.show_mobile_carousel = window.innerWidth <= 991;
		},
	}
}
