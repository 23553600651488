
import { mapActions } from 'vuex';
export default {
	layout: 'only-content',
	components: {
		vcDynamicLink	: () => import('../../../../components/pages/dynamic-link.vue'),
		vcLoading	    : () => import('../../../../components/pages/loading.vue'),
        countDown       : () => import('../../../../components/pages/count-down.vue'),
    },
    data() {
        return {
            isLoading: true
        }
    },
    async mounted() {

        this.$root.$on('end-count-down', this.endCountDown);
        if( !['null', null, 'undefined', undefined].includes(this.$route.query?.store) ){

            window.location.href = `${ this.$config.storefront_url }/${ this.$route.query.store }${ this.$route.fullPath }${ !['null', null, 'undefined', undefined].includes(this.$route.query?.code_af) ? `&code_af=${ this.$route.query.code_af }` : '' }`;
        }
        else{

            await this.getCart();
            this.isLoading = null;
            this.$root.$emit('init-count-down', 30);
        }
    },
    computed:{
        getLoading(){
            return this.isLoading;
        }
    },
    methods: {
        ...mapActions({
            getCart: 'getCart'
        }),
        endCountDown(){
            console.log( 'end count down' );
            this.$router.push('/');
        }
    }
}
