
import { mapGetters } from 'vuex';
export default {
    props: ["position"],
    data() {
        return {
            avatar		: null,
            showProfile	: false,
        }
    },
    async mounted() {
        
        this.updateProfileImage();
        this.$root.$on('update-profile-image', this.getLoginProfileImage);
    },
    computed: {
        ...mapGetters({
            getterUser              : "getterUser",
            getterLevel             : "getterLevel",
            getterProfileImage		: 'getterProfileImage'
        }),
        getProfileImage(){
            
            this.updateProfileImage();
            return this.avatar;
        },
        isShowProfile(){
            return this.showProfile;
        }
    },
    methods: {
        hideProfileImage(){
            
            this.showProfile = false;
        },
        updateProfileImage(){

            let cookie_user = this.$cookies.get('user-store');
            this.showProfile = true;
            cookie_user.profile_image = this.getterProfileImage === null ? ( this.getterUser?.profile_image || null ) : this.getterProfileImage;

            this.$cookies.set('user-store', JSON.stringify(cookie_user), { path: '/', maxAge: 60 * 60 * 24 * 7 });
            this.avatar = cookie_user.profile_image != null ? `${ this.$config.hefesto_file.baseURL }${ cookie_user.profile_image }` : null;
        },
        getLoginProfileImage(data_image){
            this.avatar = data_image != null ? `${ this.$config.hefesto_file.baseURL }${ data_image }` : null;
            this.showProfile = true;
        }
    }
}
