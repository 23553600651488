
let defaultBusinessData = {
    have_experience		: true,
    physical_store 		: true,
    target_sell 		: "physical",
    company_webside		: null,
    have_company_webside: { value: false, name: '' },
    social_networks 	: [
        { type: "facebook"	, icon: "fab fa-facebook-f"	, active: false, user: "" },
        { type: "instagram"	, icon: "fab fa-instagram"	, active: false, user: "" },
        { type: "tiktok"	, icon: "fab fa-tiktok"		, active: false, user: "" }
    ],
    business_size		: "1",
    terms_and_conditions: null,
    language			: null,
    note				: null
};
import { mapGetters } from 'vuex';
export default {
    props: ["idForm"],
    components:{
        termsAndConditions	: () => import("../../components/pages/terms-and-conditions.vue"),
        vcFormField		: () => import("../../components/pages/form-field.vue")
    },
    data() {
        return {
            businessData            : {...defaultBusinessData},
            options_languages		: [],
            options_have_experience	: [
                { label: "Yes"	, value: true },
                { label: "No"	, value: false }
            ],
            options_have_company_website	: [
                { label: "Yes"	, value: true   , field_text_name: "company-webside", field_text_label: "Company Webside", field_text_id: "company_website" },
                { label: "No"	, value: false  , field_text_name: null }
            ],
            options_physical_store	: [
                { label: "Yes"	, value: true },
                { label: "No"	, value: false }
            ],
            options_targets			: [
            { label: "Physical"	, id: "physical" },
            { label: "Virtual"	, id: "virtual" },
            { label: "Both"		, id: "both" }
            ],
            options_social_networks	: [
            { type: "facebook"	, icon: "fab fa-facebook-f"	, active: false, user: "" },
            { type: "instagram"	, icon: "fab fa-instagram"	, active: false, user: "" },
            { type: "tiktok"	, icon: "fab fa-tiktok"		, active: false, user: "" }
            ],
            options_business_size	: [
            { label: "Only me"				, id: "1" },
            { label: "From 2 to 5 people"	, id: "2-5" },
            { label: "From 5 to 10 people"	, id: "5-10" },
            { label: "More than 10 people"	, id: "10" }
            ],
            
            defaultInvest		: null,
            isDefaultInvest		: true,
            actionForm          : null
        };
    },
    async mounted() {
        
        this.options_languages = this.getterLanguages.map((item) => {
            return { label: item.name, id: item.name };
        });
        this.businessData.language = this.options_languages.find( (item) => item.label === "English" ).id;
        this.$root.$on('change-business-data', this.updateLeadData);
        this.$root.$on('valid-business-data', this.executeValidLeadData);
        this.$root.$on('reset-business-data', this.resetLeadData);
		this.$root.$on('update-field-value', this.updateFieldValue);
    },
    computed: {
        ...mapGetters({
            getterLanguages: 'getterLanguages'
        })
    },
    methods: {
        updateFieldValue(data){
            
            if( data.field === "social_networks" ){

                this.businessData[data.field] = data.value.reduce( (previous_item, current_item) =>{

                    if( current_item.active && current_item.user.trim() != "" ){

                        current_item.user = current_item.user.trim();
                        previous_item.push(current_item);
                    }
                    return previous_item;
                }, []);
            }
            else if( data.field === "have_company_webside" ){
                    
                this.businessData.have_company_webside = data.value.value;
                this.businessData.company_webside = data.value.name;
            }
			else if( this.businessData[data.field] ){

				this.businessData[data.field] = data.value;
			}   
        },
        async updateLeadData(data_form){
            
            this.businessData = data_form;
            
            this.options_social_networks = this.businessData.social_networks;
        },
        executeValidLeadData(data_form){
            
            if( data_form.id_form === this.idForm ){
                
                this.actionForm = data_form.action;
                document.getElementById(`btn-${ data_form.id_form }`).click();
            }
        },
        resetLeadData(data_form){
            
            if( data_form.clean ){
                
                this.businessData = {...defaultBusinessData};
            }
            document.getElementById(`reset-form-${ data_form.id_form }`).click();
        },
        preSubmit(is_valid){
            
            if( this.actionForm ){
                
                this.businessData.social_networks = this.options_social_networks;
                this.businessData.have_company_webside = this.businessData.have_company_webside?.value ? this.businessData.have_company_webside?.value : this.businessData.have_company_webside;
                this.businessData.company_webside = this.businessData.have_company_webside?.name ? this.businessData.have_company_webside?.name : null;
                this.$root.$emit('valid-form-business', { id_form: this.idForm, lead: this.businessData, valid: is_valid, action: this.actionForm });
            }
            this.actionForm = null;
        },
        showModalTermsAndConditions(){
            
            if( this.businessData.terms_and_conditions ){
                
                this.$refs['modal_terms_and_conditions'].show();
            }
            this.businessData.terms_and_conditions = null;
        },
        agreeTermsAndConditions(refName){
            
            this.businessData.terms_and_conditions = true;
            this.$refs[refName].hide();
        },
        hideModal(refName){
            
            this.$refs[refName].hide();
        }
    }
};
