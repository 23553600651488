
import { mapGetters } from 'vuex'
export default {
    components: {
		vcDynamicCustomLink	    : () => import('../../components/pages/dynamic-custom-link.vue'),
        dynamicComponentLoader  : () => import('../../components/pages/dynamic-component-loader.vue')
    },
    data() {
        return {
            activeItem          : ''
        }
    },
    mounted() {
        this.$root.$on('show-sidebar-menu', this.showSidebarMenu);
    },
    computed: {
        ...mapGetters({
            getterNavigations	: 'getterNavigations',
        }),
        getNavigation(){
            return this.getterNavigations?.header || [];
        },
        isActive(){
            return this.activeItem;
        }
    },
    methods: {
        showSidebarMenu(is_show){
            if (is_show) {
                this.$refs.sidebarMenu.show();
            } else {
                this.$refs.sidebarMenu.hide();
            }
        },
        setActive(menuItem) {
            
            this.activeItem = this.activeItem === menuItem ? '' : menuItem;
        },
        navItemOptionOpen(event, navigate, menuItem, type_menu, type_item){
            if( menuItem ){
                this.setActive(menuItem);
            }
            if( type_menu === 'mobile'){
                this.$refs.sidebarMenu.hide();
            }
            navigate(event);
        }
    }
}
